import React, { useRef } from 'react';

const FSVideo = ({src}) => {
  const videoRef = useRef(null);


  
  const handleVideoClick = () => {
    const video = videoRef.current;

    if (video) {
      // Lancer la vidéo en plein écran
      const requestFullscreen =
        video.requestFullscreen ||
        video.mozRequestFullScreen ||
        video.webkitRequestFullscreen ||
        video.msRequestFullscreen ||
        video.webkitEnterFullscreen; // Ajout pour iOS/Android

      if (requestFullscreen) {
        requestFullscreen.call(video);
      }

      // Démarrer la lecture
      video.play();
    }
  };


  return (
    <video
      ref={videoRef}
      controls
      poster="/images/UX/tuto/preview_tuto.png"
      onClick={handleVideoClick}
      disablePictureInPicture
      controlsList="nodownload noremoteplayback  noprogressbar"
      className='border-2 border-b-4 border-blue-900 bg-blue-500 rounded-xl mb-4'
      style={{ width: '100%', cursor: 'pointer', height: "400px" }}
    >
      <source src="https://firebasestorage.googleapis.com/v0/b/mindseed-425a8.appspot.com/o/video%2FTuto_ms_2.mp4?alt=media&token=8c338e38-c398-4e39-a34b-89b817d51c75" type="video/mp4" />
      Votre navigateur ne supporte pas la lecture de vidéos.
    </video>
  );
};

export default FSVideo;
