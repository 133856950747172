import  React, { createContext, useMemo, useContext, useState, useEffect, useRef} from 'react';


const PaintableLetter = ({ letter = 'A', opacity = 0.3 }) => {
    const canvasRef = useRef(null);
    const hiddenCanvasRef = useRef(null);
    const [isPainting, setIsPainting] = useState(false);
    const [ctx, setCtx] = useState(null);
    const [coloredPixels, setColoredPixels] = useState(0);
    const [totalPixels, setTotalPixels] = useState(1); // Initialisé à 1 pour éviter la division par zéro
    const [pixelMap, setPixelMap] = useState(null); // Nouvelle variable pour stocker les pixels de la lettre
  
    useEffect(() => {
      const canvas = canvasRef.current;
      const context = canvas.getContext('2d');
      const hiddenCanvas = hiddenCanvasRef.current;
      const hiddenContext = hiddenCanvas.getContext('2d');
  
      // Dimensionnement du canevas caché
      hiddenCanvas.width = canvas.width;
      hiddenCanvas.height = canvas.height;
  
      const fontSize = 200;
      const fontFamily = 'Arial';
  
      // Définir les propriétés de la lettre
      context.font = `${fontSize}px ${fontFamily}`;
      context.textAlign = 'center';
      context.textBaseline = 'middle';
  
      hiddenContext.font = `${fontSize}px ${fontFamily}`;
      hiddenContext.textAlign = 'center';
      hiddenContext.textBaseline = 'middle';
  
      // Calculer la position pour centrer la lettre
      const x = canvas.width / 2;
      const y = canvas.height / 2;
  
      // Dessiner la lettre en arrière-plan du canevas caché
      hiddenContext.fillStyle = '#20193EEE';
      hiddenContext.fillText(letter, x, y);
  
      // Créer un tableau binaire pour les pixels de la lettre
      const imageData = hiddenContext.getImageData(0, 0, hiddenCanvas.width, hiddenCanvas.height);
      const pixels = new Array(imageData.data?.length / 4).fill(false);
      let pixelCount = 0;
  
      for (let i = 0; i < imageData.data?.length; i += 4) {
        if (imageData.data[i + 3] > 0) { // Vérifie si le pixel est opaque (fait partie de la lettre)
          pixels[i / 4] = true;
          pixelCount++;
        }
      }
  
      setTotalPixels(pixelCount);
      setPixelMap(pixels);
  
      // Préparer le canevas visible
      context.clearRect(0, 0, canvas.width, canvas.height);
      context.fillStyle = '#20193EEE';
      context.fillText(letter, x, y);
  
      // Configuration du contexte pour le dessin
      context.globalCompositeOperation = 'source-atop';
      setCtx(context);
      setColoredPixels(0); // Réinitialiser les pixels coloriés
    }, [letter]);
  
    const getTouchPos = (canvas, touchEvent) => {
      const rect = canvas.getBoundingClientRect();
      return {
        x: touchEvent.touches[0].clientX - rect.left,
        y: touchEvent.touches[0].clientY - rect.top,
      };
    };
  
    const handleDraw = (x, y) => {
      const canvas = canvasRef.current;
      const pixelIndex = Math.floor(y) * canvas.width + Math.floor(x);
  
      // Vérifier si le pixel est dans la lettre et non encore colorié
      if (pixelMap[pixelIndex]) {
        ctx.strokeStyle = '#E77975';
        ctx.lineWidth = 36;
        ctx.lineCap = 'round';
  
        ctx.lineTo(x, y);
        ctx.stroke();
        ctx.beginPath();
        ctx.moveTo(x, y);
  
        // Marquer ce pixel comme colorié et incrémenter si c'est la première fois
        if (pixelMap[pixelIndex] !== 'colored') {
          pixelMap[pixelIndex] = 'colored';
          setColoredPixels((prev) => prev + 1);
        }
      }
    };
  
    const startPainting = (event) => {
      setIsPainting(true);
      if (event.type.includes('touch')) {
        const touchPos = getTouchPos(canvasRef.current, event);
        handleDraw(touchPos.x, touchPos.y);
      } else {
        handleDraw(event.nativeEvent.offsetX, event.nativeEvent.offsetY);
      }
    };
  
    const stopPainting = () => {
      setIsPainting(false);
      if (ctx) {
        ctx.beginPath(); // Réinitialiser le chemin pour un nouveau coup de pinceau
      }
    };
  
    const draw = (event) => {
      if (!isPainting) return;
  
      if (event.type.includes('touch')) {
        const touchPos = getTouchPos(canvasRef.current, event);
        handleDraw(touchPos.x, touchPos.y);
      } else {
        handleDraw(event.nativeEvent.offsetX, event.nativeEvent.offsetY);
      }
    };
  
    const completionPercentage = ((coloredPixels / totalPixels) * 100).toFixed(2);
  
    return (
      <div className='grow w-full relative  '>
        <canvas
          ref={canvasRef}
          width={250}
          height={250}
          onMouseDown={startPainting}
          onMouseMove={draw}
          onMouseUp={stopPainting}
          onMouseLeave={stopPainting}
          onTouchStart={startPainting}
          onTouchMove={draw}
          onTouchEnd={stopPainting}
          className='bg-black/20 rounded-xl mx-auto my-8'
          style={{ border: '1px solid black', touchAction: 'none' }}
        />
        <canvas
          ref={hiddenCanvasRef}
          style={{ display: 'none' }} // Canevas caché
        />
        {/* <p>Completion: {completionPercentage}%</p> */}
      </div>
    );
  };

  export {PaintableLetter}