import  React, { createContext, useMemo, useContext, useState, useEffect, useRef} from 'react';
import { collection, addDoc, doc, getDocs, query, updateDoc, getDoc, orderBy, limit, deleteDoc, where } from "firebase/firestore";
import { getMessaging, getToken } from "firebase/messaging";
import { getFunctions, httpsCallable } from "firebase/functions";

import { db, app } from "../../config.js";
import {fire} from '../🔥db.js'
import { useNavigate } from "react-router-dom";
import { findWord, clean, gpt, numberSentences, splitNumeredSentence, sentenceEndRegex, goodDate, shuffleArray, speak, stopSound } from "../../utilities/helper/text.js";
import SuperText from './perfectScreen.js';

import { quantum, waveform } from 'ldrs'
import { addImg, arabicLetters, displayDuration } from '../../utilities/helper/helper.js';
import { badSound, validSound } from '../components/learn/learnContainer.js';
import { getStorage } from 'firebase/storage';
import { ct } from '../../utilities/data.js';
import { LetterCard } from '../components/learn/letterCard.js';


// new 
// letter
// letter


const rosettaText= {
    "Arabe": "في يوم من الأيام، قررت الشمس أن تشرق في السماء الزرقاء وتعطي الضوء للعالم. الجبال الشامخة كانت واقفة بصمت، والشجر الأخضر يهتز مع النسيم. الأطفال يلعبون في الحقول ويضحكون بصوت عال. القمر ظهر في الليل ليضيء السماء، والنجوم كانت تلمع ببهاء. في الزاوية، كان هناك قط أسود يراقب المشهد بهدوء. الكلمات تتطاير في الهواء، والأحلام تتجسد في عقول الناس. الزهور تتفتح مع قدوم الربيع، والطيور تغني أعذب الألحان.",
    "hindi": "कभी कभी खेतों में हिरन दौड़ते हैं। आकाश में पक्षी उड़ते हैं और सूरज चमकता है। पहाड़ की चोटी पर चिड़िया बैठी होती है और नदी के किनारे गायें चरती हैं। किताबों में ज्ञान है और बगीचों में फूल खिलते हैं। जंगल में शेर गर्जता है और बारिश की बूंदें धरती पर गिरती हैं।",
    "Russe": "Жаркий день закончился, и луна взошла на небе. Деревья шумели, а звезды сверкали над рекой. На берегу сидел медведь и смотрел вдаль. Люди в деревне готовили ужин, и дети играли в поле. Кошка бежала вдоль забора, а собака лаяла на прохожих. Ночь накрыла мир тишиной и покоем.",
    "ukrainien": "Сонце сідає за горизонт, і небо стає темним. Птахи летять додому, а діти грають на вулиці. Річка тече повз ліс, і вітер гойдає дерева. Коти гуляють по двору, а собаки гавкають. Місяць піднявся високо, і зірки з’явилися на небі. Всі засинають під спокійний шум природи.",
    "chinois": "太阳从东方升起，照亮了整个天空。鸟儿在树枝上歌唱，河水静静流淌。孩子们在草地上嬉戏，风儿轻轻吹过花园。山峰高耸入云，而小鹿在森林里奔跑。夜晚降临，月亮升起，星星闪烁，整个世界进入了宁静。猫咪在角落里静静地观察着一切。",
    "chinois_latin": "Tàiyáng cóng dōngfāng shēngqǐ, zhàoliàng le zhěnggè tiānkōng. Niǎo ér zài shùzhī shàng gēchàng, héshuǐ jìngjìng liútǎng. Háizi men zài cǎodì shàng xīxì, fēng ér qīngqīng chuīguò huāyuán. Shānfēng gāosǒng rùyún, ér xiǎolù zài sēnlín lǐ bēnpǎo. Yèwǎn jiànglín, yuèliàng shēngqǐ, xīngxīng shǎnshuò, zhěnggè shìjiè jìnrù le níngjìng. Māomī zài jiǎoluò lǐ jìngjìng de guānchá zhe yīqiè.",
    "japonais": "太陽が昇り、空を照らしている。木々が風に揺れ、小鳥たちが歌う。山の上に月が出て、星が輝いている。子供たちは公園で遊び、犬が走り回る。猫は静かに庭を見つめ、夜が更けていく。静かな街の中で、夢は広がり、風が心地よく吹いている。",
    "japonais_latin": "Taiyō ga nobori, sora o terashite iru. Kigi ga kaze ni yure, kotori-tachi ga utau. Yama no ue ni tsuki ga dete, hoshi ga kagayaite iru. Kodomo-tachi wa kōen de asobi, inu ga hashirimawaru. Neko wa shizuka ni niwa o mitsume, yoru ga fukete iku. Shizuka na machi no naka de, yume wa hirogari, kaze ga kokochiyoku fuite iru.",
    "Coréen": "어느 따뜻한 봄날, 하늘은 맑고 바람은 부드러웠다. 산 속에서는 토끼가 뛰어다니고, 새들은 나무 위에서 노래했다. 바닷가에서는 갈매기가 날아다니며 물고기를 잡았다. 강가에는 작은 오두막집이 있고, 그 안에서 고양이가 창밖을 바라보고 있었다. 멀리서 늑대가 울었고, 마을의 불빛이 점점 더 희미해졌다.",

}


function highlightLetter(letters, text) {
    // Transform the array of letters to a Set for efficient lookup
    const letterSet = new Set(letters);
  
    // Map over each character in the text and wrap it in a span if it matches a letter in the set
    return text.split('').map((char, index) => {
      if (letterSet.has(char)) {
        return (
          <span key={index} className={"text-[#FFCD4C]"}>{char}</span>
        );
      } else {
        return <span key={index}>{char}</span>;
      }
    });
  }


const getLetters = async(set, workspace, set2) => {
    console.log('get Letters 🟢🟢🟢')
    const q = query(collection(db, "cards"), where('workspace_id', '==', workspace.id), where('type', '==', 'letter'))
    const querySnapshot = await getDocs(q);
    const data = querySnapshot.docs.map(doc => ({...doc.data(), id: doc.id }))
    set(data)
    set2(data)
    
    console.log('data', data)
}


// new A
// new B
// 
// audio A
// audio B
// audio A



const EditCard = ({_card, fake, workspace, storage}) => {



    const [card, setCard] = useState(_card)


    const addCard = async (card) => {

        const newCard = {...card, id: null, workspace_id: workspace.id, type: "letter"}
        const doc = await addDoc(collection(db, "cards"), newCard)
        console.log('doc', doc)
    }

    const delCard = async (card) => {
        await deleteDoc(doc(db, "cards", card.id))
        setCard()
    }



    const updateCard = async (card) => {
        setCard(card)
        console.log('new card', card)
        const updatedCard = await updateDoc(doc(db, "cards", card.id), card)
    }

    const addImage = async (card) => {
     
        const src = await addImg(`
        Donne moi une image (sans aucun texte) qui représente le mot suivant '${card.word_fr}'.
        Dans le style illustration jolie, moderne et minimaliste (sans trop de complexité) au format 1024x1024.
        Aucun mots ni écriture mots écris doit ne doit apparaitre. Aucun texte sur l'image`, storage, card.id)
        const newCard = {...card, img: src}
        console.log('newCard', newCard)
        console.log('src', src)
        const updatedCard = await updateDoc(doc(db, "cards", card.id), newCard)
        setCard(newCard)
    }


  



    return <div onClick={() => console.log('card', card)} className='bg-white/50 w-[900px] gap-2 border-b-2 text-blue-800 flex'>
            <div className='w-[50px] border-r'>
                <input className='w-[50px]'  onBlur={(e) => {updateCard({...card, rank: parseInt(e.currentTarget.value)})}} defaultValue={card?.rank}/>
                <input className='w-[50px] text-green-500'  onBlur={(e) => {updateCard({...card, rank_l: parseInt(e.currentTarget.value)})}} defaultValue={card?.rank_l}/>

            </div>
            <img onClick={() => addImage(card)} src={card?.img} className='h-[50px] w-[50px]'/>
            <div className='w-[50px] border-r'>
                <input className='w-[50px]'  onBlur={(e) => {updateCard({...card, letter: e.currentTarget.value})}} defaultValue={card?.letter} placeholder={"letter"}/> 
                <input className='w-[50px]'  onBlur={(e) => {updateCard({...card, latin: e.currentTarget.value})}}  defaultValue={card?.latin} placeholder={"latin"}/>
            </div>
            <div  className='w-[100px] relative mr-2 group'>
                <input className='w-[100px]' onBlur={(e) => {updateCard({...card, word: e.currentTarget.value})}} defaultValue={card?.word} placeholder={"word"}/>
                <input className='w-[100px]' onBlur={(e) => {updateCard({...card, word_fr: e.currentTarget.value})}} defaultValue={card?.word_fr} placeholder={"word_fr"}/>
                <input className='w-[100px]' onBlur={(e) => {updateCard({...card, word_latin: e.currentTarget.value})}} defaultValue={card?.word_latin} placeholder={"word_latin"}/>
                <div className='absolute top-0 right-0 hover:scale-[1.5]' onClick={() => {
                    console.log('coucou edition')
                }}>🪄</div>
            </div>

            <div  className='w-[70px] relative mr-2 group'>
                <input className='w-[70px]'  onBlur={(e) => {updateCard({...card, part: e.currentTarget.value})}} placeholder='part' defaultValue={card?.part}/>
                <input className='w-[70px]'  onBlur={(e) => {updateCard({...card, cat: e.currentTarget.value})}} placeholder='cat' defaultValue={card?.cat}/>

            </div>

            <div  className='w-[200px] relative mr-2 group'>
                <textarea placeholder='note' onBlur={(e) => {updateCard({...card, note: e.currentTarget.value})}} className='w-[200px] text-xs' defaultValue={card?.note}/>
               
                <div className='absolute top-0 right-0 hover:scale-[1.5]' onClick={() => {}}>🪄</div>
            </div>

          


            {fake && <div onClick={() => addCard(card)}>Ajouter</div>}
            {_card?.id && <div className='text-red-300' onClick={() => delCard(card)}>supprimer</div>}
           
            
        </div>
}

function filterUniqueLetters(cards) {
    const seenLetters = new Set();
    const uniqueCards = [];
  
    for (const card of cards) {
      if (!seenLetters.has(card.letter)) {
        seenLetters.add(card.letter);
        uniqueCards.push(card);
      }
    }
  
    return uniqueCards;
  }




const LearnView = ({cardToLearn, setCardToLearn, setUserCards, userCards, user, workspace, otherCards}) => {

    const [cards, setCards] = useState()
    const [indexCard, setIndexCard] = useState(0)


    const currentElement = cards?.[indexCard]
    let cardOnly = [...cardToLearn.map(c => c.card), ...otherCards].slice(0, 20);
  
 
    
   
    const currentCard = currentElement?.card
    const currentUserCard = userCards?.find(uc => uc.card_id == currentCard?.id) 
    const lang = workspace.lang


    useEffect(() => {
        setCards(cardToLearn.map(c => ({...c, done: 0})))
    }, [])
    

    // séparer lecture de écriture ? ecriture izi
    const getIndex = async (valid) => {

        valid ? validSound.play() : badSound.play()
        // trouver les cartes avec done == null
        // trouve la carte avec done le plus bas


        // const currentTrigger = ((currentElement?.userCard?.triggers?.[0]?.lvl || 0) + 1) % 2 || 0
        const currentTrigger = currentElement?.userCard?.next_trigger


        let new_userCard = {
            ...currentElement.userCard,
            card_id: currentElement.card.id,
            user_id: user.id, 
            workspace_id: workspace.id,
            lastUptate: new Date(),
            letter: true, 
            valid,
            next_trigger: currentElement?.userCard?.next_trigger == 0 ? 1 : 0,
            triggers: [
                {
                  lvl: Math.max(
                    0,
                    (currentElement?.userCard?.triggers?.[0]?.lvl || 0) +
                    (valid && currentTrigger == 0 ? 1 
                    :!valid && currentTrigger == 0 ? -2 : 0)
                  )
                },
                {
                  lvl: Math.max(
                    0,
                    (currentElement?.userCard?.triggers?.[1]?.lvl || 0) +
                    (valid && currentTrigger == 1 ? 1 
                  : !valid && currentTrigger == 1 ? -2 : 0)
                  )
                }
              ]
            
          }






      


        //   console.log('currentElement', currentElement)
          console.log('currentUserCard', currentUserCard)
          console.log('new_userCard', new_userCard)
          let id = null
        if (!currentUserCard) {
            console.log('création de user_card !!')
            // création user cards
            const ref = await addDoc(collection(db, "user_cards"), new_userCard)
            id = ref.id
            console.log('ref', ref)
            new_userCard = {...new_userCard, id: ref.id}
           
            setUserCards(uc => [...uc, {...new_userCard, valid: null, id: ref.id}])
            
        } else {
            console.log('update de user_card !!', new_userCard)
            const ref = await updateDoc(doc(db, "user_cards", new_userCard.id), new_userCard)
           
            console.log('ref', ref)
         
            setUserCards(uc => uc.map(u => u.card_id == new_userCard.card_id ? {...new_userCard, valid: null} : u))
           
        }

        const new_card = {
            ...currentElement,
            userCard: new_userCard
          };
        
       


        console.log('new_card', new_card)
        console.log('cards', cards)

        const updatedCards = cards.map((c,i) => c.card.id == new_card.card?.id ? {...c, userCard: new_userCard} : c)
        console.log('updatedCards', updatedCards)
        const _new_cards = updatedCards.map((c,i) => i == indexCard ? {...c, done: c.done + 1, valid: valid} : c)
        console.log('_new_cards', _new_cards)



        setCards(_new_cards)
        const new_index = cards
        .map((card, index) => ({ ...card, originalIndex: index })) // Ajoute l'index original à chaque carte
        .filter(card => !card.valid && indexCard != card.originalIndex) // Filtre les cartes non valides
        .sort((a, b) => a.done - b.done) // Trie par valeur croissante de 'done'
        .map(card => card.originalIndex) // Récupère l'index original de la carte triée
        .shift(); // Prend l'index de la première carte (celle avec le 'done' le plus faible)



        if (new_index == -1 || new_index == undefined) {
            setCardToLearn()
        
            
            return }
    
        setIndexCard(new_index)
    
        
            
        
    }



    


    // mettre le trigger automatique dans LetterCard
    // compter point double pour carte new 

   

    const validCard = cards?.filter(card => card.valid)?.length
    const cent = Math.round(validCard * 100 / (cards?.length - 1))

    const progressBar = <div className='bg-black/20 transition-all  grow border relative overflow-hidden border-black/20 border-b-2 h-4 rounded-xl'>
        <div className='bg-white/40 h-[3px] transition-all absolute mx-2 mt-[2px] rounded-xl' style={{width: `calc(${cent}% - 10px)`}}></div>
        <div className='bg-black/10 h-[5px] transition-all absolute mx-2 mt-[6px] rounded-xl' style={{width: `calc(${cent}% - 10px)`}}></div>


        <div className='bg-yellow-500 transition-all h-4 rounded-xl' style={{width: `${cent}%`}}></div>
    </div>


    return <div className='bg-purple-900 flex flex-col justify-between items-center p-4 bg-rosette fixed top-0 left-0 right-0 bottom-0'>



    
      <div className='flex gap-2 items-center  w-full'>
            <div onClick={() => setCardToLearn()}><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M9 15 3 9m0 0 6-6M3 9h12a6 6 0 0 1 0 12h-3" />
                </svg>
                </div>
            {progressBar}


            <div></div>
        </div>
        {/* <div className='flex gap-1'>{cards?.map((e, i) => <div className={`${i == indexCard ? "text-yellow-500 " : e.valid && "text-green-500"}`}>{e.done}</div>)}</div> */}
        {/* index: {indexCard} 
        total: {cardToLearn?.length}  */}


        <div className='grow flex flex-col justify-center'>
        {currentCard && <LetterCard lang={lang} getIndex={getIndex} trigger={"new_card"} 
        cards={filterUniqueLetters(cardOnly)} 
        // cards={cards}
        card={currentCard} userCard={currentUserCard} />}
        </div>

        {/* <div onClick={() => {
            console.log('cards', cards)

        }}>
            <div>Info</div> 
            <div>current cart: {currentCard?.letter}</div>
            <div>userCard: {JSON.stringify(currentElement?.userCard)}</div>
        </div> */}
     
    </div>

}


  










// charactère chinois dans la pièrre de rosette ? 
// apprendre uniquement le pinyin ici
// puis 10 premiers caractères (ici ou direct dans le cours)





const LetterMini = ({index, card, userCard, setShowCard, goalLvl, lang, isAdmin}) => {

    const round = (Math.ceil((index+1)/4) || 1) - 1
    const lvlMax = goalLvl - round


    let print = card.letter
    
    if (card.isTon) print = card.latin
    if (card.pinyin_i) print = card.pinyin_i
    if (card.pinyin_f) print = card.pinyin_f


    return <div onClick={async() => {
        const endSound = await speak({text: card.letter , mp3: card.mp3, lang: lang})
        if (endSound) {
            setShowCard({card: card, userCard: userCard});
        }
       
        // console.log('card ??', card)
        // stopSound()
        // card.isSign ?  speak({text: card.letter , mp3: card.mp3, lang: lang}) : speak({text: card.letter + "; ... " + card.word ,  mp3: card.mp3, lang: lang});
        }} className='border-2 text-center px-3 rounded-xl   py-1 border-b-4 border-black/30'>
        <div className='text-4xl'>{print}</div>
        {/* <div className='text-white/40 fredoka'>{card.isTon ? "" : card.latin}</div> */}
        <div className='text-white/30 text-xs flex gap-2 justify-center h-[12px] items-center'>
            {/* <span>#{card.rank}</span> */}
            {isAdmin && <>
                <span className='text-green-200/50'>{userCard?.triggers?.[0].lvl || 0}/{lvlMax}</span>
                <span className='text-purple-200/50'>{userCard?.triggers?.[1].lvl || 0}/{lvlMax}</span>
            </>}
            
        </div>
        </div>
}









const RosetaScreen = (props) => {
    const navigate = useNavigate()
    const [lastCall, setLastCall] = useState()
    const menu = props.menu

    const workspace = props.workspace




    


    const [cards, setCards] = useState([])

    useEffect(() => {
        getLetters(setCards, workspace)
    } , [])





    const Fakecards = [
        {
            letter: "啊",
            latin: "a",
            pinyin_f: "a",
            word: "啊",
            word_latin: "a",
            word_fr: "ah",
            sentence: "啊，你来了！",
            sentence_fr: "Ah, tu es là !",
            hint: "Exclamation",
            rank: 20,
            type: "letter",
            part: "final",
            isRadical: false
        },
        {
            letter: "爱",
            latin: "ài",
            pinyin_f: "ai",
            word: "爱",
            word_latin: "ài",
            word_fr: "aimer",
            sentence: "我爱你。",
            sentence_fr: "Je t'aime.",
            hint: "Exprime l'amour",
            rank: 21,
            type: "letter",
            part: "final",
            isRadical: false
        },
        {
            letter: "奥",
            latin: "ào",
            pinyin_f: "ao",
            word: "奥",
            word_latin: "ào",
            word_fr: "profondeur",
            sentence: "这个问题很奥。",
            sentence_fr: "Cette question est profonde.",
            hint: "Profondeur ou sagesse",
            rank: 22,
            type: "letter",
            part: "final",
            isRadical: false
        },
        {
            letter: "安",
            latin: "ān",
            pinyin_f: "an",
            word: "安",
            word_latin: "ān",
            word_fr: "paix",
            sentence: "我希望你平安。",
            sentence_fr: "Je te souhaite la paix.",
            hint: "Lié à la tranquillité",
            rank: 23,
            type: "letter",
            part: "final",
            isRadical: false
        },
        {
            letter: "昂",
            latin: "áng",
            pinyin_f: "ang",
            word: "昂",
            word_latin: "áng",
            word_fr: "élever",
            sentence: "昂首挺胸。",
            sentence_fr: "Tête haute, poitrine en avant.",
            hint: "Indique la hauteur ou l'élévation",
            rank: 24,
            type: "letter",
            part: "final",
            isRadical: false
        },
        {
            letter: "鹅",
            latin: "é",
            pinyin_f: "e",
            word: "鹅",
            word_latin: "é",
            word_fr: "oie",
            sentence: "这是一只鹅。",
            sentence_fr: "C'est une oie.",
            hint: "Animal",
            rank: 25,
            type: "letter",
            part: "final",
            isRadical: false
        },
        {
            letter: "诶",
            latin: "éi",
            pinyin_f: "ei",
            word: "诶",
            word_latin: "éi",
            word_fr: "hey",
            sentence: "诶，你在干嘛？",
            sentence_fr: "Hé, qu'est-ce que tu fais ?",
            hint: "Exclamation pour attirer l'attention",
            rank: 26,
            type: "letter",
            part: "final",
            isRadical: false
        },
        {
            letter: "恩",
            latin: "ēn",
            pinyin_f: "en",
            word: "恩",
            word_latin: "ēn",
            word_fr: "grâce",
            sentence: "谢谢你的恩情。",
            sentence_fr: "Merci pour ta grâce.",
            hint: "Gratitude ou faveur",
            rank: 27,
            type: "letter",
            part: "final",
            isRadical: false
        },
        {
            letter: "鞥",
            latin: "ēng",
            pinyin_f: "eng",
            word: "鞥",
            word_latin: "ēng",
            word_fr: "son de force",
            sentence: "声音很鞥。",
            sentence_fr: "Le son est puissant.",
            hint: "Indique un son de force",
            rank: 28,
            type: "letter",
            part: "final",
            isRadical: false
        },
        {
            letter: "哦",
            latin: "ó",
            pinyin_f: "o",
            word: "哦",
            word_latin: "ó",
            word_fr: "oh",
            sentence: "哦，我明白了。",
            sentence_fr: "Oh, j'ai compris.",
            hint: "Exclamation de réalisation",
            rank: 29,
            type: "letter",
            part: "final",
            isRadical: false
        },
        {
            letter: "欧",
            latin: "ōu",
            pinyin_f: "ou",
            word: "欧",
            word_latin: "ōu",
            word_fr: "Europe",
            sentence: "他住在欧洲。",
            sentence_fr: "Il vit en Europe.",
            hint: "Lié à l'Europe",
            rank: 30,
            type: "letter",
            part: "final",
            isRadical: false
        },
        {
            letter: "翁",
            latin: "wēng",
            pinyin_f: "ong",
            word: "翁",
            word_latin: "wēng",
            word_fr: "vieil homme",
            sentence: "老人被称作翁。",
            sentence_fr: "Un vieil homme est appelé 'wēng'.",
            hint: "Respect pour un vieil homme",
            rank: 31,
            type: "letter",
            part: "final",
            isRadical: false
        },
        {
            letter: "一",
            latin: "yī",
            pinyin_f: "i",
            word: "一",
            word_latin: "yī",
            word_fr: "un",
            sentence: "我有一个苹果。",
            sentence_fr: "J'ai une pomme.",
            hint: "Chiffre 'un'",
            rank: 32,
            type: "letter",
            part: "final",
            isRadical: false
        },
        {
            letter: "呀",
            latin: "yā",
            pinyin_f: "ia",
            word: "呀",
            word_latin: "yā",
            word_fr: "ah",
            sentence: "呀，你来了！",
            sentence_fr: "Ah, tu es là !",
            hint: "Exclamation légère",
            rank: 33,
            type: "letter",
            part: "final",
            isRadical: false
        },
        {
            letter: "也",
            latin: "yě",
            pinyin_f: "ie",
            word: "也",
            word_latin: "yě",
            word_fr: "aussi",
            sentence: "我也是学生。",
            sentence_fr: "Je suis aussi étudiant.",
            hint: "Indique aussi, également",
            rank: 34,
            type: "letter",
            part: "final",
            isRadical: false
        },
        {
            letter: "要",
            latin: "yào",
            pinyin_f: "iao",
            word: "要",
            word_latin: "yào",
            word_fr: "vouloir",
            sentence: "我想要水。",
            sentence_fr: "Je veux de l'eau.",
            hint: "Exprime un désir ou un besoin",
            rank: 35,
            type: "letter",
            part: "final",
            isRadical: false
        },
        {
            letter: "有",
            latin: "yǒu",
            pinyin_f: "iu",
            word: "有",
            word_latin: "yǒu",
            word_fr: "avoir",
            sentence: "我有一本书。",
            sentence_fr: "J'ai un livre.",
            hint: "Exprime la possession",
            rank: 36,
            type: "letter",
            part: "final",
            isRadical: false
        },
        {
            letter: "言",
            latin: "yán",
            pinyin_f: "ian",
            word: "言",
            word_latin: "yán",
            word_fr: "parole",
            sentence: "他说了什么？",
            sentence_fr: "Que dit-il ?",
            hint: "Se rapporte à la parole",
            rank: 37,
            type: "letter",
            part: "final",
            isRadical: false
        },
        {
            letter: "因",
            latin: "yīn",
            pinyin_f: "in",
            word: "因",
            word_latin: "yīn",
            word_fr: "cause",
            sentence: "原因是什么？",
            sentence_fr: "Quelle est la cause ?",
            hint: "Indique la cause ou le motif",
            rank: 38,
            type: "letter",
            part: "final",
            isRadical: false
        },
        {
            letter: "阳",
            latin: "yáng",
            pinyin_f: "iang",
            word: "阳",
            word_latin: "yáng",
            word_fr: "soleil",
            sentence: "今天太阳很大。",
            sentence_fr: "Il y a beaucoup de soleil aujourd'hui.",
            hint: "Se rapporte au soleil ou à la lumière",
            rank: 39,
            type: "letter",
            part: "final",
            isRadical: false
        },
        {
            letter: "赢",
            latin: "yíng",
            pinyin_f: "ing",
            word: "赢",
            word_latin: "yíng",
            word_fr: "gagner",
            sentence: "他赢了比赛。",
            sentence_fr: "Il a gagné le match.",
            hint: "Indique la victoire",
            rank: 40,
            type: "letter",
            part: "final",
            isRadical: false
        },
        {
            letter: "永",
            latin: "yǒng",
            pinyin_f: "iong",
            word: "永",
            word_latin: "yǒng",
            word_fr: "éternité",
            sentence: "爱情是永恒的。",
            sentence_fr: "L'amour est éternel.",
            hint: "Se rapporte à la permanence ou à l'éternité",
            rank: 41,
            type: "letter",
            part: "final",
            isRadical: false
        },
        {
            letter: "乌",
            latin: "wū",
            pinyin_f: "u",
            word: "乌",
            word_latin: "wū",
            word_fr: "corbeau",
            sentence: "乌鸦很聪明。",
            sentence_fr: "Le corbeau est très intelligent.",
            hint: "Un oiseau noir",
            rank: 42,
            type: "letter",
            part: "final",
            isRadical: false
        },
        {
            letter: "娃",
            latin: "wá",
            pinyin_f: "ua",
            word: "娃",
            word_latin: "wá",
            word_fr: "poupée, bébé",
            sentence: "她有一个洋娃娃。",
            sentence_fr: "Elle a une poupée.",
            hint: "Se rapporte aux enfants ou aux poupées",
            rank: 43,
            type: "letter",
            part: "final",
            isRadical: false
        },
        {
            letter: "我",
            latin: "wǒ",
            pinyin_f: "wo",
            word: "我",
            word_latin: "wǒ",
            word_fr: "je, moi",
            sentence: "我是学生。",
            sentence_fr: "Je suis étudiant.",
            hint: "Se réfère à la première personne du singulier",
            rank: 44,
            type: "letter",
            part: "final",
            isRadical: false
        }
    ]
    
    

    console.log('fakeCAd', Fakecards)
    
    
    
    
    




    
    const userCards = props.userCards
    const setUserCards = props.setUserCards

    // console.log('userCards', userCards)
    
    const cardWithData = cards.map(e => ({card: e, userCard: userCards.find(uc => uc.card_id == e.id)}))
    .sort((a,b) => a.card.rank - b.card.rank)
     // on format en user_card

     


    const [showCard, setShowCard] = useState()
    const [cardToLearn, setCardToLearn] = useState()

    useEffect(() => {
        console.log("StoryScreen loaded")
        props.hideMenuBar(!cardToLearn?.length)

    }, [cardToLearn])

    // console.log('cardWithData', cardWithData)

 



    // interface rosetta avec plusieurs phrases qui non aucun sens. et contienne toutes les lettres de l'alphabet.
    // mettre pierre de rosette déchiffré à 80%. Tu es maitrise x% de l'alphabet russe..
    // !! impossible de le faire avec le chinois.
    // voir tuto dans chaque langue, qui explique les spécificités.

    // comment faire pour les signes ? 
    // pinying 
    // pierre de rosette hsk1 ? ou juste les 10 premiers signes 


    const newCards = [...cardWithData.filter(e => !e.userCard)]
    const cardToReview = [...cardWithData.filter(e => e.userCard)]
    const cardLearned = [...cardWithData.filter(e => e.userCard)]




    console.log('cardWithData', cardWithData)
    console.log('cardToReview', cardToReview)


    // ajouter reviewCards (card avec des userCards)


    // on prends les cards "news", 


    // ajouter les dates et si tout est bon niveau date remettre système de stacking
    const goalLvl = Math.round((cardLearned?.length / 4) + 1)
    const cardToReviewT1 = [...cardWithData.filter(e => e.userCard?.triggers[0].lvl < goalLvl)]
    const cardToReviewT2 = [...cardWithData.filter(e => e.userCard?.triggers[1].lvl < goalLvl)]
    // 4 -> 1
    // 8 -> 2
    // 12 -> 3





    const lettersToHighlight = cardWithData.filter(c => c.userCard).map(c => c.card.letter)

      

      function shuffleArray(array) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    }
    
    function shuffleWithoutConsecutiveDuplicates(cards) {
        let shuffledCards = shuffleArray(cards);
    
        for (let i = 1; i < shuffledCards.length; i++) {
            if (shuffledCards[i].card.id === shuffledCards[i - 1].card.id) {
                // Rechercher un autre index avec une carte différente pour échanger
                let swapIndex = -1;
                for (let j = i + 1; j < shuffledCards.length; j++) {
                    if (shuffledCards[j].card.id !== shuffledCards[i].card.id) {
                        swapIndex = j;
                        break;
                    }
                }
                if (swapIndex !== -1) {
                    // Échanger les cartes pour éviter les doublons consécutifs
                    [shuffledCards[i], shuffledCards[swapIndex]] = [shuffledCards[swapIndex], shuffledCards[i]];
                }
            }
        }
    
        return shuffledCards;
    }
    
    const allCardToReview = [
        ...cardToReviewT1,
        ...cardToReviewT2,
        ...cardToReviewT1,
        ...cardToReviewT2,
    ];
    
    // Mélanger et organiser les cartes
    const randomizedCards = shuffleWithoutConsecutiveDuplicates(allCardToReview);

    console.log('randomizedCards', randomizedCards)


    const nextLetter = [
        // ajouter les révisions ici 
        // si pas de revision ajouter revision de demain ici 
        ...randomizedCards,
        newCards?.[0], 
        newCards?.[1], 
        newCards?.[0],
        newCards?.[1],
        newCards?.[2],
        newCards?.[0],
        newCards?.[1],
        newCards?.[2],
        newCards?.[3],
        newCards?.[2],
        newCards?.[3],
        newCards?.[3],

    ].filter(Boolean)
        ?.slice(0, 20)


        console.log('nextLetter', nextLetter)


        // faire aussi exercice dérevision

        const showModes = [
            {id: "letter", fr: "Lettre", icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0ZM3.75 12h.007v.008H3.75V12Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm-.375 5.25h.007v.008H3.75v-.008Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z" />
          </svg>
          }, 
            {id: "pierre", fr: "Pierre", icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 0 1 .865-.501 48.172 48.172 0 0 0 3.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z" />
          </svg>
          }, 
            {id: "edit", fr: "Editer", icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
          </svg>
          }, 
       ]

        const [showMode, setShowMode] = useState(showModes[0].id)

        const dbCards = null


       

       
        const storage = getStorage(app);

        const user = props.user
    

        console.log('ct user',  ct.user)
        console.log('ct ',  ct)
        console.log('props user ',  props.user)
        const [selectedCat, setSelectedCat] = useState(null)

       


        let categories = []

        if (workspace?.id == "japonais") {
            categories = [{id: "kata", fr: "Katakana"}, {id: "hiragana", fr: "Hiragana"}]
        }

        if (workspace?.id == "chinois") {
            categories = [
                {id: "pinyin", fr: "Pinyin"}, 
                {id: "hanzi", fr: "Hanzi"},
                {id: "radicaux", fr: "Radicaux"}
            ]
        }   


        useEffect(() => {
            setSelectedCat(categories[0])
        }, [workspace])

        const groupByCategory = (data) => {
            return data.reduce((acc, item) => {
              if (!acc[item.card.part]) {
                acc[item.card.part] = [];
              }
              acc[item.card.part].push(item);
              return acc;
            }, {});
          };

          const groupedCard = groupByCategory(cardWithData.filter(e => (!selectedCat || (e.card.cat == selectedCat.id))));


          console.log('groupedCard', groupedCard)



    return <div className="px-2 text-white rounded-xl mb-2 mt-2 ">



        <div className='arab font-sans	 text-2xl text-white/40 noto mx-auto w-[170px] mb-[70px] overflow-hidden h-[170px] text-[#48403F] font-semibold text-sm'>
        
        
        {highlightLetter(lettersToHighlight, rosettaText[workspace.id])}
        {/* <div className='text-center my-4'>{Math.round(lettersToHighlight?.length*100/cards?.length)}%</div> */}

        {/* <div className={`bg-gradient-to-b  from-indigo-500  to-blue-500 border-black/90 rounded-xl border-2 border-b-4 col-span-3 md:col-span-5 text-white   lg:col-span-8 overflow-hidden relative fredoka text-shadow flex items-center justify-between gap-4 px-3 font-bold text-[18px] h-[70px] fond-bold text-center border-2 my-4 p-1 rounded overflow-visible `}>
                          <div className="text-left font-base">Planter des graines<div className="text-left text-xs nunito">Pour muscler ta mémoire et ne rien oublier</div> </div>
                          <img src="/images/seeds/blue-2.png" className="absolute  opacity-[70%] right-[-10px] -top-[36px] h-[120px] " />
                          <img src="/images/seeds/green-2.png" className="absolute  opacity-[70%] right-[-10px] -top-[6px] h-[60px] " />
                          <img src="/images/seeds/blue-2.png" className="absolute  opacity-[70%] right-[40px] -top-[12px] h-[80px] " />
              </div> */}
        
        </div>
            



       {categories.length > 0 &&  <div className='flex items-center justify-center my-4'><div className='flex bg-white/10 rounded-xl px-3  items-center justify-center gap-2'>
            {categories.map(c => <div onClick={() => setSelectedCat(c)} className={`${c.id == selectedCat?.id ? "text-white/90" :  "text-white/40"}`}>{c.fr}</div>)}
        </div></div>}


        {user.admin && <svg onClick={() => {setShowMode(e => e == "letter" ? "edit" : "letter")}} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`${showMode == "letter"?  "opacity-[0.2]" : "opacity-50"} h-6 absolute top-2 right-2`}>
            <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
          </svg>}

        {/* <div className='flex gap-2 mb-6 justify-center '>{showModes.map(e => <div className={`${e.id == showMode ? "text-white/90 underline" : "text-white/30" } px-2 `} onClick={() => setShowMode(e.id)}>{e.icon}</div>)}</div> */}
    

        {/* <PaintableLetter letter={"ج"}  /> */}
        {/* <DrawingCanvas letter={"ج"} /> */}

       

    

        <div className='fixed bottom-[80px] left-0 right-0 flex items-center justify-center'><div className='px-8 rounded-xl bg-purple-500 border-2 border-b-4 py-2 text-center border-black'  onClick={() => setCardToLearn(nextLetter)}>Apprendre</div></div>

        {cardToLearn && <LearnView otherCards={cards} user={user} workspace={workspace} userCards={userCards} setUserCards={props.setUserCards} cardToLearn={cardToLearn} setCardToLearn={setCardToLearn} />}

        {showCard && <div onClick={() => {setShowCard()}} className='fixed bg-black/20 flex items-center justify-center top-0 left-0 right-0 bottom-0'>
            <div onClick={(e) => e.stopPropagation()}><LetterCard  defaultTrigger={"null"} lang={workspace.lang} cards={cards} card={showCard.card} userCard={showCard.userCard} /></div>
            </div>}

       {/* <div className='text-center fredoka mb-4 text-2xl'>Pierre de rosette</div> */}

       

       {showMode == "letter" && 
       <div className='text-white'>
       {Object.keys(groupedCard).map((category) => (
           <div key={category}>
           <div className='mt-4'>{category}</div>
           <ul>
               <div className='grid grid-cols-4 gap-2 flex-wrap'>
                   {groupedCard[category].sort((a,b) => a.card.rank - b.card.rank).filter(e => (!selectedCat || (e.card.cat == selectedCat.id))).map((e, i) => <LetterMini isAdmin={user.admin} index={i} lang={workspace.lang} goalLvl={goalLvl} setShowCard={setShowCard} userCard={e.userCard} card={e.card} />)}
                   </div>

               {/* {groupedCard[category].map((item, index) => (
               <li onClick={() => {console.log('items', item)}} key={index}>- {item?.letter}</li>
               ))} */}
           </ul>
           </div>
       ))}
       </div>
        // <div className='grid grid-cols-4 gap-2 flex-wrap'>{cardWithData.sort((a,b) => a.card.rank - b.card.rank).filter(e => (!selectedCat || (e.card.cat == selectedCat.id))).map((e, i) => <LetterMini index={i} lang={workspace.lang} goalLvl={goalLvl} setShowCard={setShowCard} userCard={e.userCard} card={e.card} />)}</div>
       }
       
       
       {user.admin && <div>
       {showMode == "edit" && <div className='p-4 bg-white overflow-scroll rounded border-2 text-blue-700'>

     
        <div className='border-b-4 '>fake: </div>
        {/* {Fakecards?.map(e => <EditCard workspace={workspace} setShowCard={setShowCard} _card={e} fake={true} />)} */}
        <div onClick={() =>   getLetters(setCards, workspace)}>getLetters</div>
        {cardWithData.sort((a,b) => a.card.rank - b.card.rank).map(e => <EditCard  storage={storage} workspace={workspace} setShowCard={setShowCard} _card={e.card}  />)}
        <EditCard storage={storage} setShowCard={setShowCard} />
        </div>}

        <div className='mt-16 p-3 text-center' onClick={() => {
            userCards?.forEach(e => deleteDoc(doc(db, 'user_cards', e.id)))
            setUserCards([])
            }}>réinitialiser {userCards?.length} cartes</div>
        </div>}



       {/* <div>goal {goalLvl}</div>
        <div>learned: {cardLearned.length}</div>

        Liste des stacks
        {nextLetter?.map( e => <div className='flex gap-2'><div className='w-12'>{e?.card?.letter} </div> 
        <div>
            <span className='text-green-500'>{e.userCard?.triggers?.[0]?.lvl}</span> - 
            <span className='text-purple-300'>{e.userCard?.triggers?.[1]?.lvl}</span>
            <span className='text-purple-300'>next_t: {e.userCard?.next_trigger}</span>
        </div>
        </div>) } */}

      </div>

};

export {RosetaScreen};





