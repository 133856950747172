import  React, { createContext, useMemo, useContext, useState, useEffect, useRef} from 'react';
import { PaintableLetter } from './paintableLetter';
import { shuffleArray, speak, stopSound } from '../../../utilities/helper/text';
import { badSound, validSound } from './learnContainer';
import { arabicLetters, highlightLetterArabic } from '../../../utilities/helper/helper';





const tons = [
    {
        "letter": "妈",
        "latin": "mā"
    },
    {
        "letter": "麻",
        "latin": "má"
    },
    {
        "letter": "马",
        "latin": "mǎ"
    },
    {
        "letter": "骂",
        "latin": "mà"
    }
]



const AudioBtn = ({text, lang, mp3}) => (
    <div onClick={() =>  speak({text: text, mp3: mp3, lang: lang})} className='h-[100px] flex items-center justify-center w-[100px] rounded-[30px] bg-[#32246a] border-2 border-b-4 border-black/20'>
            <svg   xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="text-pink-100 w-12 h-12"><path stroke-linecap="round" stroke-linejoin="round" d="M19.114 5.636a9 9 0 010 12.728M16.463 8.288a5.25 5.25 0 010 7.424M6.75 8.25l4.72-4.72a.75.75 0 011.28.53v15.88a.75.75 0 01-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.01 9.01 0 012.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75z"></path></svg>        

    </div>
)




const highlightText = (letter, text, text_latin, lang, className) => {
    const regex = new RegExp(`(${letter})`, 'g');
    const parts = text.split(regex);

   

    if (lang == "ar-XA") {
        
        return <div>
            <div>{highlightLetterArabic([letter], text, className)}</div>
            {/* <div>{text}</div> */}
            </div>
    }

    return (
        <div className='text-4xl'>
            {parts.map((part, i) =>
                part === letter ? (
                    <span key={i} className={`` + className } style={{ color: 'white' }}>{part}</span>
                ) : (
                    <span key={i} className='text-white/50'>{part}</span>
                )
            )} 
        </div>
    );
}


const InputQCM = ({fied_id, difficulty, soundMode, array, correct, validAction, lang, card, setError, error}) => {

    const answer = correct[`${fied_id}`]
    const [selectedProps, setSelectedProps] = useState()
    const [preSelectedProps, setPreSelectedProps] = useState()
    const selectedAnswer = array[selectedProps]?.[`${fied_id}`]





    console.log('array', array)

    useEffect(() => {
        if(!selectedAnswer) return
        console.log('selectedAnswer',selectedAnswer)
        console.log('answer',answer)
        if (selectedAnswer == answer) {
            validAction()
            validSound.play()
            speak({text: card.letter + "; ... " + card.word , lang: lang})
        } else {
            badSound.play()
            setError(e => (e || 0) + 1)
            // speak({text: card.letter + "; ... " + card.word , lang: lang})
        }

        console.log('array', array)
    }, [selectedAnswer])


    return <div className={`mt-4 p-1 rounded 

    `}>
        <div className={`grid text-xl ${difficulty == 2 ?"grid-cols-3" : "grid-cols-2"}  gap-2`}>
        {array.map((e, i) => <div onClick={() => {
            if (soundMode) {
            setPreSelectedProps(i)
            speak({text: e[`letter`] , lang: lang}) 
            } else {
            setSelectedProps(i)
            } 
        }} className={`border-2 ${ selectedProps == i ? answer == e[`${fied_id}`] ? "bg-green-500" : "bg-red-500" : ""} ${preSelectedProps == i  ? "bg-blue-500" : ""} px-2 py-2 grow rounded-xl border-black/40 border-b-4 text-3xl text-center`}>{
        soundMode ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6 mx-auto">
        <path strokeLinecap="round" strokeLinejoin="round" d="M19.114 5.636a9 9 0 0 1 0 12.728M16.463 8.288a5.25 5.25 0 0 1 0 7.424M6.75 8.25l4.72-4.72a.75.75 0 0 1 1.28.53v15.88a.75.75 0 0 1-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.009 9.009 0 0 1 2.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75Z" />
      </svg>
      : e[`${fied_id}`]
        }</div>)}
        </div>
        {soundMode ? <div onClick={() => {setSelectedProps(preSelectedProps)}} className={` ${preSelectedProps == null ? "opacity-50" : ""} px-6 p-4 bg-black/10 border-2 mt-2 border-b-4 border-black/50 text-center rounded-xl`} >Valider</div> : ""}
    </div>
    }


const LetterCard = ({card, userCard, cards, defaultTrigger, getIndex, lang}) => {   


    const [triggerMode, setTriggerMode] = useState()
    const [inputState, setInputState] = useState(0)
    const [randomCards, setRandomCards] = useState([])
    const [error, setError] = useState(0)

    const [difficulty, setDifficulty] = useState()

    const lvl_trigger = userCard?.triggers?.[userCard?.next_trigger]?.lvl
    const modeExercice = lvl_trigger > 4 ? lvl_trigger%2 == 0 ? 1 : 0 : 0





    useEffect(() => {
        setError(0)
        setInputState(0)
        stopSound()
        setDifficulty()

        
       
        const lvl_trigger = userCard?.triggers?.[userCard?.next_trigger]?.lvl
        let contextMode = lvl_trigger%2 == 0

        
       
        if (!userCard?.next_trigger) 
            {setTriggerMode("new_card")
            if (card.isTon) {
                setTriggerMode("ton_new")
                
            }

            if (card.cat == "pinyin") {
                setTriggerMode("pinyin_new")
                
            }
        
        } 
            
    
        if (userCard?.next_trigger == 1) {
            if (card.isTon) {
                setTriggerMode("ton_audio")
                return
            }
            if (card.cat == "pinyin") {
                setTriggerMode("pinyin_audio")
                return
            }

            setTriggerMode(contextMode ? "audio_letter_context": "audio_letter" )
            speak({text: contextMode ? card.word : card.letter , lang: lang}) 
        } 
        if (userCard?.next_trigger == 0)    {
            if (card.isTon) {
                setTriggerMode("ton_write")
                return
            }
            if (card.cat == "pinyin") {
                setTriggerMode("pinyin_latin")
                return
            }
            setTriggerMode(contextMode ?  "letter_latin" : "letter_latin_context" )
        }

           
    }, [userCard, card.id])


    useEffect(() => {
        let otherCards = shuffleArray(cards.filter(c => c.letter != card.letter)) 
        if (card.isTon) otherCards = shuffleArray(tons.filter(c => c.letter != card.letter)) 
        
        // prendre en priorité 2 cartes déjà apprisent
        let lenght = 3
        if (difficulty == 0) { lenght = 1}
        if (difficulty == 2) { lenght = 5}
        const _randomCards = shuffleArray([card, ...otherCards.slice(0,lenght)]) 
        console.log('------_randomCards', _randomCards)
        setRandomCards(_randomCards)
        console.log('----cards', cards)
    }, 
    [cards?.length, card, difficulty])



    const inputOpen = (easyMode = false) => <div onClick={() => {navigator?.vibrate(200);}} className='flex -mx-1 justify-between items-center  gap-1'>
    <div onClick={() => {setDifficulty(0); setInputState(e => e+1)}} className='border-2 mb-2 px-1 h-[55px] flex items-center justify-center text-sm grow w-[100%] rounded-xl border-black/40 border-b-4 text-center'>Je sais pas</div>
    <div onClick={() => {setDifficulty(1); setInputState(e => e+1)}} className='border-2 mb-2 px-1 h-[55px] flex items-center justify-center text-sm grow w-[100%] rounded-xl border-black/40 border-b-4 text-center'>Je sais</div>
    {easyMode && <div onClick={() => {setDifficulty(2); setInputState(e => e+1)}} className='border-2 mb-2 px-1 h-[55px] flex items-center justify-center text-sm grow w-[100%] rounded-xl border-black/40 border-b-4 text-center'>Trop facile</div>}

</div>



    const ton_audio = (n) => <div className='p-4 relative h-full text-red-400'>
    <div className='mx-auto mt-[100px] flex justify-center'>
        <svg  onClick={() =>  speak({text: card.letter , mp3: card.mp3, lang: lang})} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="text-[#FF62A4] w-12 h-12"><path stroke-linecap="round" stroke-linejoin="round" d="M19.114 5.636a9 9 0 010 12.728M16.463 8.288a5.25 5.25 0 010 7.424M6.75 8.25l4.72-4.72a.75.75 0 011.28.53v15.88a.75.75 0 01-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.01 9.01 0 012.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75z"></path></svg>        
    </div>
    <div   onClick={() => {getIndex(true)}} className='text-center text-white/70  mt-8'>Quelle ton fais ce son ?</div>

    <div  id="input" className='text-white absolute bg-input bg-black/10 border border-black/10 min-h-[130px] rounded-b bottom-0 p-4 left-0 right-0'>

        {n == 0 && <>
            <InputQCM difficulty={difficulty} error={error} setError={setError} card={card} lang={lang} fied_id='latin' array={randomCards} correct={card} validAction={() => {speak({text: card.letter + "; ... " + card.word , lang: lang}) ; setTriggerMode()}} />
        </>}
        {n == 1 && <>
        {inputState == 0 && inputOpen() }
        {inputState == 1 && <InputQCM difficulty={difficulty} error={error} setError={setError} card={card} lang={lang} fied_id='latin' array={randomCards} correct={card} validAction={() => {speak({text: card.letter + "; ... " + card.word , lang: lang}) ; setTriggerMode()}} />}
        </>}
    </div>
    </div>



            // faire les correlaction 4 sons à droite 4 lettre à gauche avec les sons (mais s'adapte pas au cerveau de l'utilisateur )
        const ton_write = (n) => <div className='p-4 relative h-full text-red-400'>
        <div className='mx-auto mt-[100px] flex justify-center'>
            <svg  onClick={() =>  speak({text: card.letter , lang: lang})} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="text-[#FF62A4] w-12 h-12"><path stroke-linecap="round" stroke-linejoin="round" d="M19.114 5.636a9 9 0 010 12.728M16.463 8.288a5.25 5.25 0 010 7.424M6.75 8.25l4.72-4.72a.75.75 0 011.28.53v15.88a.75.75 0 01-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.01 9.01 0 012.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75z"></path></svg>        
        </div>
        <div   onClick={() => {getIndex(true)}} className='text-center text-white/70  mt-8'>Quelle ton fais ce son ?</div>

        <div  id="input" className='text-white absolute bg-input bg-black/10 border border-black/10 min-h-[130px] rounded-b bottom-0 p-4 left-0 right-0'>

            {n == 0 && <>
                <InputQCM difficulty={difficulty} error={error} setError={setError} card={card} lang={lang} fied_id='latin' array={randomCards} correct={card} validAction={() => {speak({text: card.letter + "; ... " + card.word , lang: lang}) ; setTriggerMode()}} />
            </>}
            {n == 1 && <>
            {inputState == 0 && inputOpen() }
            {inputState == 1 && <InputQCM difficulty={difficulty} error={error} setError={setError} card={card} lang={lang} fied_id='latin' array={randomCards} correct={card} validAction={() => {speak({text: card.letter + "; ... " + card.word , lang: lang}) ; setTriggerMode()}} />}
            </>}
        </div>
        </div>

    const ton_new = (n) => <div className='p-4 relative h-full  text-red-400'>
        <div className='flex justify-center absolute top-2 left-0 right-0'><div onClick={() => {getIndex(true)}} className='text-center text-yellow/70 mt-2 text-xs flex gap-2 mx-auto'>Nouveau <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-4">
     <path strokeLinecap="round" strokeLinejoin="round" d="M9.813 15.904 9 18.75l-.813-2.846a4.5 4.5 0 0 0-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 0 0 3.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 0 0 3.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 0 0-3.09 3.09ZM18.259 8.715 18 9.75l-.259-1.035a3.375 3.375 0 0 0-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 0 0 2.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 0 0 2.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 0 0-2.456 2.456ZM16.894 20.567 16.5 21.75l-.394-1.183a2.25 2.25 0 0 0-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 0 0 1.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 0 0 1.423 1.423l1.183.394-1.183.394a2.25 2.25 0 0 0-1.423 1.423Z" />
   </svg>
   </div></div>
    <div className='mx-auto mt-[100px] flex justify-center'>
        <AudioBtn text={card.letter} mp3={card.mp3} lang={lang}/>
    </div>
    <div   onClick={() => {getIndex(true)}} className='text-center text-white/70  mt-8'>Quelle ton fais ce son ?</div>

    <div  id="input" className='text-white absolute bg-input bg-black/10 border border-black/10 min-h-[130px] rounded-b bottom-0 p-4 left-0 right-0'>

        {n == 0 && <>
            <InputQCM difficulty={difficulty} error={error} setError={setError} card={card} lang={lang} fied_id='latin' array={randomCards} correct={card} validAction={() => {speak({text: card.letter + "; ... " + card.word , lang: lang}) ; setTriggerMode()}} />
        </>}
        {n == 1 && <>
        {inputState == 0 && inputOpen() }
        {inputState == 1 && <InputQCM difficulty={difficulty} error={error} setError={setError} card={card} lang={lang} fied_id='latin' array={randomCards} correct={card} validAction={() => {speak({text: card.letter + "; ... " + card.word , lang: lang}) ; setTriggerMode()}} />}
        </>}
    </div>
    </div>



        const pinyin_new = <div className='p-4 relative h-full text-red-400'>
            
        <div className='flex justify-center'><div onClick={() => {getIndex(true)}} className='text-center text-yellow/70 mt-2 text-xs flex gap-2 mx-auto'>Nouveau <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-4">
     <path strokeLinecap="round" strokeLinejoin="round" d="M9.813 15.904 9 18.75l-.813-2.846a4.5 4.5 0 0 0-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 0 0 3.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 0 0 3.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 0 0-3.09 3.09ZM18.259 8.715 18 9.75l-.259-1.035a3.375 3.375 0 0 0-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 0 0 2.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 0 0 2.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 0 0-2.456 2.456ZM16.894 20.567 16.5 21.75l-.394-1.183a2.25 2.25 0 0 0-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 0 0 1.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 0 0 1.423 1.423l1.183.394-1.183.394a2.25 2.25 0 0 0-1.423 1.423Z" />
   </svg>
   </div></div>
           <div>
               <div onClick={() => {getIndex(true)}} className='text-center text-white/70 mt-8 mb-2'>Comment se prononce ce mot ?</div>
                   <div className='mx-auto text-[80px] mt-8 mb-4 flex justify-center text-white'>
                   {card.latin}
                   </div>
   
               <InputQCM difficulty={difficulty} error={error} setError={() => {}} card={card} lang={lang} soundMode={true} fied_id='latin' array={randomCards} correct={card} validAction={() => { speak({text: card.letter + ". ; ... , " + card.word , lang: lang}); setTriggerMode()}} />
               </div>
       </div>


        const pinyin_audio = <div className='p-4 relative h-full text-red-400'>
           
        <div className='mx-auto mt-[100px] flex justify-center'>
            <AudioBtn text={card.letter} lang={lang} />
        </div>
        {card.cat != "pinyin" &&<div onLoad={() => speak({text: card.letter , lang: lang}) } className='text-center'>{card.latin}</div>}
        <div   onClick={() => {getIndex(true)}} className='text-center text-white/70  mt-8'>Quelle lettre fais ce son ?</div>

        <div  id="input" className='text-white absolute bg-input bg-black/10 border border-black/10 min-h-[130px] rounded-b bottom-0 p-4 left-0 right-0'>
            {inputState == 0 && inputOpen(true) }
            {inputState == 1 && <InputQCM difficulty={difficulty} error={error} setError={setError} card={card} lang={lang} fied_id={card.cat != "pinyin" ? 'letter' : "latin"} array={randomCards} correct={card} validAction={() => {speak({text: card.letter + "; ... " + card.word , lang: lang}) ; setTriggerMode()}} />}
        </div>
    </div>
        
        
        const pinyin_latin = (lvl) => <div className='p-4 relative h-full text-red-400'>
             <div>pinyin latin</div>
        <div className='mx-auto text-[60px] mt-16 flex justify-center'>
        {card.cat != "pinyin" ? card.letter : card.latin}
        </div>
        <div   onClick={() => {getIndex(true)}} className='text-center text-white/70 mt-8'>Comment se prononce cette lettre ? </div>
    
        <div  id="input" className='text-white absolute bg-black/10 bg-input rounded-b bottom-0 p-4 left-0 right-0'>
            {inputState == 0 && inputOpen(true) }
            {inputState == 1 && lvl == 0 && <InputQCM difficulty={difficulty} error={error} setError={setError} card={card} lang={lang} fied_id='latin' soundMode={difficulty != 2 || card.cat == "pinyin"} array={randomCards} correct={card} validAction={() => setTriggerMode()} />}
            {inputState == 1 && lvl == 1 && <InputQCM difficulty={difficulty} error={error} setError={setError} card={card} lang={lang} fied_id='latin' array={randomCards} correct={card} validAction={() => setTriggerMode()} />}
        </div>
        </div>



  


    const new_card = <div className='p-4 relative h-full text-red-400'>
     <div className='flex justify-center'><div onClick={() => {getIndex(true)}} className='text-center text-yellow/70 mt-2 text-xs flex gap-2 mx-auto'>Nouveau <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-4">
  <path strokeLinecap="round" strokeLinejoin="round" d="M9.813 15.904 9 18.75l-.813-2.846a4.5 4.5 0 0 0-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 0 0 3.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 0 0 3.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 0 0-3.09 3.09ZM18.259 8.715 18 9.75l-.259-1.035a3.375 3.375 0 0 0-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 0 0 2.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 0 0 2.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 0 0-2.456 2.456ZM16.894 20.567 16.5 21.75l-.394-1.183a2.25 2.25 0 0 0-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 0 0 1.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 0 0 1.423 1.423l1.183.394-1.183.394a2.25 2.25 0 0 0-1.423 1.423Z" />
</svg>
</div></div>
        {inputState == 0 && <div>
            <div onClick={() => {getIndex(true)}}  className='text-center text-sm text-purple-300 text-white/70 mt-8 mb-2'>Dessine la lettre</div>

            <PaintableLetter letter={card.letter}  />
            <div onClick={() => {setInputState(e => e+1); validSound.play()}} className='border-2 mb-2 px-2 py-2 grow text-white/90 mt-2 rounded-xl border-black/40 border-b-4 text-center'>Suivant</div>
            </div>}
        {inputState == 1 && <div>
            <div onClick={() => {getIndex(true)}} className='text-center text-white/70 mt-8 mb-2'>Comment se prononce cette lettre ?</div>
                <div className='mx-auto text-[80px] mt-8 mb-4 flex justify-center text-white'>
                {card.letter}
                </div>

            <InputQCM difficulty={difficulty} error={error} setError={() => {}} card={card} lang={lang} fied_id='latin' array={randomCards} correct={card} validAction={() => { speak({text: card.letter + ". ; ... , " + card.word , lang: lang}); setTriggerMode()}} />
            </div>} 
        

    </div>

    const audio_letter = <div className='p-4 relative h-full text-red-400'>
            <div className='mx-auto mt-[100px] flex justify-center'>
                <svg  onClick={() =>  speak({text: card.letter , lang: lang})} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="text-[#FF62A4] w-12 h-12"><path stroke-linecap="round" stroke-linejoin="round" d="M19.114 5.636a9 9 0 010 12.728M16.463 8.288a5.25 5.25 0 010 7.424M6.75 8.25l4.72-4.72a.75.75 0 011.28.53v15.88a.75.75 0 01-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.01 9.01 0 012.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75z"></path></svg>        
            </div>
            {card.cat != "pinyin" &&<div onLoad={() => speak({text: card.letter , lang: lang}) } className='text-center'>{card.latin}</div>}
            <div   onClick={() => {getIndex(true)}} className='text-center text-white/70  mt-8'>Quelle lettre fais ce son ?</div>

            <div  id="input" className='text-white absolute bg-input bg-black/10 border border-black/10 min-h-[130px] rounded-b bottom-0 p-4 left-0 right-0'>
                {inputState == 0 && inputOpen(true) }
                {inputState == 1 && <InputQCM difficulty={difficulty} error={error} setError={setError} card={card} lang={lang} fied_id={card.cat != "pinyin" ? 'letter' : "latin"} array={randomCards} correct={card} validAction={() => {speak({text: card.letter + "; ... " + card.word , lang: lang}) ; setTriggerMode()}} />}
            </div>
        </div>

        const audio_letter_context = (lvl = 0) => <div className='p-4 relative h-full text-red-400'>
                    <img className='h-[100px] w-[100px] rounded-full mx-auto bg-pink-100/10 border-[5px] border-white/20'  src={card.img}/>

        <div onLoad={() => speak({text: card.letter , lang: lang}) }  className='mx-auto mt-2 flex justify-center'>
            <svg onClick={() =>  speak({text: card.word , lang: lang})} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="text-[#FF62A4] w-12 h-12"><path stroke-linecap="round" stroke-linejoin="round" d="M19.114 5.636a9 9 0 010 12.728M16.463 8.288a5.25 5.25 0 010 7.424M6.75 8.25l4.72-4.72a.75.75 0 011.28.53v15.88a.75.75 0 01-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.01 9.01 0 012.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75z"></path></svg>        
        </div>
        <div className='text-center text-xs'>{card.word_latin}</div>
        {lvl == 0 && <div  onClick={() => {getIndex(true)}} className='text-center text-white/70 mt-4'>Quelle lettre manque t-il ?</div> }
        {lvl == 1 && <div  onClick={() => {getIndex(true)}} className='text-center text-white/70 mt-8'>Comment écrire ce mot ?</div> }
        {lvl == 0 && <div className='text-center text-4xl mt-2 text-white/90'>{highlightText(card.letter, card.word, card.word_latin, lang, "text-transparent px-1 border-b-2 border-red-200")}</div>}


        <div  id="input" className='text-white absolute bg-black/10 border border-black/10 rounded-b-xl bg-input bottom-0 p-4 left-0 right-0'>
            {inputState == 0 && inputOpen(true) }
            {inputState == 1 && lvl == 0 && <InputQCM difficulty={difficulty} error={error} setError={setError} card={card} lang={lang} fied_id='letter' array={randomCards} correct={card} validAction={() => {speak({text: card.letter + "; ... " + card.word , lang: lang}) ; setTriggerMode()}} />}
            {inputState == 1 && lvl == 1 && <InputQCM difficulty={difficulty} error={error} setError={setError} card={card} lang={lang} fied_id='word' array={randomCards} correct={card} validAction={() => {speak({text: card.letter + "; ... " + card.word , lang: lang}) ; setTriggerMode()}} />}

        </div>
        </div>

    const letter_latin = (lvl = 0)=> <div className='p-4 relative h-full text-red-400'>
    <div className='mx-auto text-[60px] mt-16 flex justify-center'>
    {card.cat != "pinyin" ? card.letter : card.latin}
    </div>
    <div   onClick={() => {getIndex(true)}} className='text-center text-white/70 mt-8'>Comment se prononce cette lettre ? </div>

    <div  id="input" className='text-white absolute bg-black/10 bg-input rounded-b bottom-0 p-4 left-0 right-0'>
        {inputState == 0 && inputOpen(true) }
        {inputState == 1 && lvl == 0 && <InputQCM difficulty={difficulty} error={error} setError={setError} card={card} lang={lang} fied_id='latin' soundMode={difficulty != 2 || card.cat == "pinyin"} array={randomCards} correct={card} validAction={() => setTriggerMode()} />}
        {inputState == 1 && lvl == 1 && <InputQCM difficulty={difficulty} error={error} setError={setError} card={card} lang={lang} fied_id='latin' array={randomCards} correct={card} validAction={() => setTriggerMode()} />}
    </div>
    </div>

    const letter_latin_context = (lvl = 0) => <div className='p-4 relative h-full text-red-400'>

        <img className='h-[140px] w-[140px] rounded-full mx-auto bg-pink-100/10 border-[5px] border-white/20'  src={card.img}/>

    <div className='mx-auto text-[60px] flex justify-center text-white/60'>
     {highlightText(card.letter, card.word, card.word_latin, lang)}
    </div>
    {lvl == 0 && <div  onClick={() => {getIndex(true)}} className='text-center text-white/70 mt-2'>Comment se prononce la partie en rose ?</div>}
    {lvl == 1 && <div  onClick={() => {getIndex(true)}} className='text-center text-white/70 mt-2'>Comment se prononce ce mot ?</div>}

    <div  id="input" className='text-white absolute bottom-0 p-4 left-0 right-0'>
        {inputState == 0 && inputOpen(true) }
        {inputState == 1 && lvl == 0 && <InputQCM difficulty={difficulty} error={error} setError={setError} card={card} lang={lang} fied_id='latin' array={randomCards} correct={card} validAction={() => setTriggerMode()} />}
        {inputState == 1 && lvl == 1 && <InputQCM difficulty={difficulty} error={error} setError={setError} card={card} lang={lang} fied_id='word_latin' array={randomCards} correct={card} validAction={() => setTriggerMode()} />}

    </div>
    </div>


    const back = <div className='p-4 relative overflow-scroll  text-center'style={{maxHeight: "calc(100% - 80px)"}}>
      
    <div className='mb-6 flex flex-col items-center' onClick={() =>  speak({text: card.letter , mp3: card.mp3, lang: lang})}>
        <div className='relative -mt-4 text-[70px] min-w-[50px]   flex justify-center'>{card.letter}
            <svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="text-[#FF62A4] absolute top-2 -right-[30px] mt-1 rounded-full w-7 h-7"><path stroke-linecap="round" stroke-linejoin="round" d="M19.114 5.636a9 9 0 010 12.728M16.463 8.288a5.25 5.25 0 010 7.424M6.75 8.25l4.72-4.72a.75.75 0 011.28.53v15.88a.75.75 0 01-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.01 9.01 0 012.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75z"></path></svg>        

            <div className='w-[120px] lg:   hover:scale-110 z-[0] top-[10px] left-[-36px] absolute h-[120px] bg-white/10 rounded-full '></div>
        </div>
        <div className='text-[#FF62A4] -mt-2 fredoka'>{card.latin}</div>
    </div>

    <div className='mb-6 flex gap-4 items-center'>
        <img className='h-[180px] w-[180px] rounded-full -ml-8 bg-pink-100/10 border-8 border-white/20'  src={card.img}/>
        <div className='relative'>  
        <svg onClick={() =>  speak({text: card.word , lang: lang})} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="text-[#FF62A4] absolute top-0 -right-[30px] mt-1 rounded-full w-5 h-5"><path stroke-linecap="round" stroke-linejoin="round" d="M19.114 5.636a9 9 0 010 12.728M16.463 8.288a5.25 5.25 0 010 7.424M6.75 8.25l4.72-4.72a.75.75 0 011.28.53v15.88a.75.75 0 01-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.01 9.01 0 012.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75z"></path></svg>        

            <div className='text-[50px]'>{highlightText(card.letter, card.word, card.word_latin, lang)}</div>
            <div className='text-pink-400 text-xs mt-1 fredoka text-left'>{card.word_latin}</div>
            <div className='text-white/40 fredoka text-left'>{card.word_fr}</div>
        </div>
    </div>

    {/* <div className='text-white/70 fredoka'>{card.sentence}</div>
    <div className='text-white/40 fredoka'>{card.sentence_fr}</div> */}


    {arabicLetters[card?.letter]?.length > 0 && <div>
    <div className='text-xs text-white/40 -mt-4'>Variations</div>
    <div className='gap-4 flex justify-center'>{arabicLetters[card?.letter]?.slice(1, 4).map((v) => <div className='text-[45px]'>{v}</div>)}</div>

    {card.note && <div>
        <div className='text-xs text-white/40 mt-2'>Note</div>
        <div className='text-md text-white/90 '>{card?.note}</div>
    </div>
    }


    {/* <div className='gap-2 flex justify-center'>{card.variants?.map((v) => <div className='text-3xl'>{v.letter}</div>)}</div> */}
    {/* {card.variants.map((v, index) => {
            const regex = new RegExp(`(${card.letter})`, 'g');
            const parts = v.word.split(regex);
            return (
                <div key={index} className='text-2xl'>
                    {parts.map((part, i) =>
                        part === card.letter ? (
                            <span key={i} style={{ color: '#FF62A4' }}>{part}</span>
                        ) : (
                            <span key={i}>{part}</span>
                        )
                    )} <div className='text-xs text-white/40'>{v.word_latin}</div>
                </div>
            );
        })} */}
    </div>}
    
</div>

    

    const validationBtn = <div className='flex gap-2 mt-4 p-3 left-0 right-0 absolute bottom-0 ' >
        {error > 0 ? <div className='px-4 py-2 grow rounded-xl hover:scale-[1.1] cursor-pointer border-2  border-b-4 border-black/70 bg-white/10 text-center ' onClick={() => {getIndex(false)}}>A revoir</div> : <div></div>}
        {error == 0 ? <div className='px-4 py-2 grow  rounded-xl hover:scale-[1.1] cursor-pointer border-2  border-b-4 border-black/70 bg-white/10 text-center ' onClick={() => {getIndex(true)}}>Suivant</div> : <div></div>}
    </div>

   


    if (defaultTrigger) {
       return  <div onClick={(e) => e.preventDefault()} className={`bg-[#433182] ${triggerMode == "null" ? "max-h-[69vh] h-[500px]" : "max-h-[80vh] h-[600px]"} relative mx-auto  w-[400px] max-w-[90vw]  border-4 border-b-8 border-black/50 rounded-2xl`}>
        {back}
       </div>

    }

    return <div onClick={(e) => e.preventDefault()} className={`bg-[#433182] ${triggerMode == "null" ? "max-h-[69vh] h-[500px]" : "max-h-[80vh] h-[600px]"} relative mx-auto  w-[400px] max-w-[90vw]  overflow-auto border-4 border-b-8 border-black/50 rounded-2xl`}>
        {/* <div>info: current trigger lvl: {userCard ? userCard?.triggers?.[0]?.lvl : "new !!"} - next trigger: {userCard?.next_trigger}</div> */}
        {/* letter: {card.letter} isTon: {card.isTon ? "oui" : "non"} error: {error}; cards: {cards.length} mode: {modeExercice} lvl_trigger: {lvl_trigger} {lvl_trigger%2}%2 triggerMode: {triggerMode} inputState: {inputState} */}
        

        
        {!triggerMode && back}
        {triggerMode == "new_card" && new_card} 
        {triggerMode == "letter_latin" && letter_latin(lvl_trigger%2==1?  1 :0)}    
        {triggerMode == "audio_letter" && audio_letter}
        {triggerMode == "pinyin_new" && pinyin_new} 
        {triggerMode == "pinyin_audio" && pinyin_audio}
        {triggerMode == "pinyin_latin" && pinyin_latin(0)}
        {triggerMode == "audio_letter_context" &&  audio_letter_context(modeExercice)}
        {triggerMode == "letter_latin_context" && letter_latin_context(modeExercice)}
        {triggerMode == "ton_audio" && ton_audio(0)}
        {triggerMode == "ton_new" && ton_new(0)}
        {!triggerMode && validationBtn}


        
    </div>
}

// modifier newCard pour les pinyin
// modifier audio_letter pour pinyin -- check
// modifier letter_latin pour pinyin 
// -- mettre audio plus difficile dans hardmode
// supprimer les context trigger pour pinyin

// attention au contexte
// afficher la lettre en surligné 

export {LetterCard}