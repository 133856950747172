import { useState, useRef, useEffect } from "react";
import { TutoSRS, tutoFlashcards, tutoForest, tutoPareto, tutoPerf } from "../../utilities/helper/helper";
import { InteractivePage } from "../components/interactivePages";
import FSVideo from "../components/FSvideo";


const TutoBook = ({showTutoBook, setShowTutoBook, setShowTuto, context}) => {



    console.log('showTutoBook', showTutoBook)
    const tutoChapters = [
      {name: "Les flashcards", id: "flashcard", pages: tutoFlashcards},
      {name: "Le SRS", id: "srs", pages: TutoSRS},
      {name: "La forêt", id: "forest", pages: tutoForest },
      {name: "Les parcours perfectionnement", id:"tutoPerf", pages: tutoPerf},
      {name: "Le parcours découverte", id: "pareto", pages: tutoPareto(context)},
  
    ]
  
    const selectedTutoChapters = tutoChapters.find(e => e.id == showTutoBook)
  
  
    return <>
    {selectedTutoChapters && <div id="backdrop" className='flex flex-col items-between justify-between'  style={{ position: "fixed", top: 0, right: 0, bottom: 0, left: 0, backgroundColor: "rgba(0, 50, 0, 0.5)", zIndex: 999}}><InteractivePage 
    close={() => setShowTutoBook("all")} 
    endAction={() => {
      // ct.setUser(u => ({...u, ["tuto_" + goals[selectedGoal].goal_id]: true})) 
      // updateDoc( doc(db, 'users', ct.user.id), {["tuto_" + goals[selectedGoal].goal_id]: true});
    }} 
    pages={selectedTutoChapters.pages} 
    back={() => {setShowTutoBook("all")}}
    ct={context}
    /></div>}
   {showTutoBook && <div  className='overflow-auto'  style={{ position: "fixed", top: 0, right: 0, bottom: 0, left: 0, backgroundColor: "#fff1d7", zIndex: 199}}>
     <div className='max-w-[700px] fredoka p-4 mx-auto'>
    <div className='flex mb-8  justify-between items-center'>
     
     <img src="/images/UX/i_book.png" className='w-[50px]' />
     <div className='text-2xl text-slate-600'>Tutoriels </div>
     <div className='w-[50px]' onClick={() => setShowTutoBook()}>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6 mx-auto">
        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
      </svg>
    </div>
     </div>       
     <FSVideo />   
     {/* <video controls poster="path/to/preview-image.jpg" ontimeupdate="this.currentTime=Math.min(this.currentTime, this.duration - 0.1)" disablePictureInPicture controlsList="nodownload noremoteplayback nofullscreen noprogressbar">
        <source src="/video/Méthode_MindSeed_1.mov" type="video/mp4"/>
        Votre navigateur ne supporte pas la lecture de vidéos.
    </video> */}

     <div className='bg-blue-500 p-4 rounded-xl text-white border-2 border-b-4 border-black/50 mb-2' onClick={() => setShowTuto("Tutoriel")}>Introduction </div>
     {tutoChapters.map(e => <div className='bg-blue-500 p-4 rounded-xl text-white border-2 border-b-4 border-black/50 mb-2' onClick={() => setShowTutoBook(e.id)}>{e.name}</div>)}
  
    </div></div>}</>
  }

  
  export {TutoBook}