import { createContext, useContext, useState, useEffect, useRef} from 'react';
import { collection, addDoc } from "firebase/firestore";

import { db } from "../../config.js";



const InteractivePage = (props) => {

    
    
    const pages = props.pages || []
    const [indexPage, setIndexPage] = useState(0)
    const maxPage = pages.length -1 
    const currentPage = pages[indexPage] || null


    const progressBars = Array.from({ length: maxPage }, (_, index) => {
        if (index < indexPage) {
          return { state: "passed" };
        } else if (index === indexPage) {
          return { state: "current" };
        } else {
          return { state: null };
        }
      });

    


  console.log('props', props)
  const passed = progressBars.filter(e => (e.state == "passed" || e.state == "current")).length - 1

  const backBtn = props.close ? <div  onClick={() => {props.close(); }} className='px-3 py-1 rounded-xl border-2 border-white/10 bg-white/20 text-[#035B38]'>← Retour</div> : <div></div>

  return (
    <div className={`bg-[#F8FFFC] fredoka w-full h-full`}>
       
        <div className='absolute top-0 right-0 left-0 z-10 relative flex gap-1 items-center'>
        <div className='h-[5px] w-full absolute top-0 left-0 right-0 '>
          <div className='bg-green-500 h-[5px] rounded-r-xl  transition-all' style={{width: `${passed*100/(progressBars.length-1)}%`}}></div>
        </div>
        {/* {progressBars?.map(p => <div className={`transition-all ${p.state == "current" ? "max-w-[20px]":"max-w-[10px]" } ${p.state == "passed" || p.state =="current"  ? "bg-[#67EDB7] " :"bg-[#C9FFEA] "  }  rounded-full h-[4px] grow`}></div>)} */}
        </div>
        {!currentPage?.contentFullPage && <div className=' h-[55vh] max-w-[500px] mx-auto relative'>
            {currentPage?.bubbleGreen && <div className='absolute rounded-full right-[-150px] top-[-150px] h-[400px] w-[400px] bg-[#67EDB7]'></div>}
            <img className={currentPage?.classNameImg || 'h-[190px] top-[50px] absolute right-[50px]  rounded-xl'} src={currentPage?.img} />
        </div>}
        <div className={`p-4 ${currentPage.contentFullPage ?  "relative" : "h-[36vh]"}  max-w-[500px] mx-auto relative`}>
        <div className='absolute z-0 top-[-40px] left-[-100px] h-[300px] rounded-full w-[300px] bg-[#C9FFEA]'></div>

            <div className='text-xl relative z-[10] text-[#035B38] font-semibold'>{currentPage?.title}</div>
            <div className='text-[#007647] relative'>{currentPage?.content}</div>
            
            
            <div  className={` ${props.bottomBar ? " bottom-[70px] " : " bottom-[20px] "} fixed z-[999] p-4 flex gap-2 justify-between left-0 right-0`}>
                {indexPage == 0 ? backBtn: <div  onClick={() => setIndexPage(i => i-1 <= 0 ? 0 : i-1)} className='px-3 py-1 rounded-xl border-2 border-white/10 bg-white/20 text-[#035B38]'>← Retour</div>}
                {indexPage == maxPage ?  <div onClick={() => {props.close(); props.endAction()}} className='bg-purple-500 px-4 py-1 rounded-xl border-2 border-b-4 border-black/10 text-white'>Commencer</div>: <div onClick={() => setIndexPage(i => i == maxPage ? maxPage : i+1 )} className='px-4 py-1 rounded-xl border-2 border-b-4 border-black/10 bg-[#C9FFEA] text-[#035B38]'>Suivant →</div>}
            </div>

        </div>
    </div>
  );
};

export {InteractivePage};