import React, { useRef, useEffect, useState, useCallback, useMemo } from 'react';
import {BrainSVG, brainSVG, comparerSansAccents, displayDuration, neuroHints, pickRandomElements, pops, requestInfoMore, shuffleArray} from '../../../utilities/helper/helper'
import {addBracket, gpt, reduceInput, replaceBracketsWithInput, replaceBracketsWithUnderLine, stopSound, textSize, textSizeXl} from '../../../utilities/helper/text'
import { BrowserRouter as Router, Routes, Route, Link, useParams, useLocation, useNavigate } from 'react-router-dom';
import {models} from '../../../utilities/models';
import { SuperEditor } from "../../../components/editor.js"
import {getTitle, getCons, speak, capitalizeFirstLetter, textLvl} from '../../../utilities/helper/text'
import {validCard, learn, nextUserCard, boosters} from './learn'
import { db, app } from "../../../config.js";
import { collection, addDoc, updateDoc,deleteDoc, setDoc, getDoc, where, writeBatch, query, orderBy, doc, limit, getDocs } from "firebase/firestore";

import {Howl, Howler} from 'howler';
import { ct } from '../../../utilities/data.js';
import { DisplayMore } from '../magnifier.js';



const TargetSentence = ({text, trad, _showTrad}) => {
  const [showTrad, setShowTrad] = useState(_showTrad || true)

  return <div>
    <div className='text-indigo-600 text-sm'>{text}</div>
    {!showTrad ? <div className='text-xs opacity-50' onClick={() => setShowTrad(true)}>Voir la traduction</div> : null}
    {showTrad ? <div onClick={() => setShowTrad(false)} className='text-xs opacity-50'>{trad}</div> : null}
  </div>

}

const findHint = async ({term, lang}) => {
  const messages =[{"role": "user", "content": `
  Trouve un lien mnémotechnique (en français) pour m'aider à retenir le mots ${lang} "${term}", 
  en m'aidant à imaginer une image qui lie "${term}" et sa traduction en français. 
  Donne uniquement le liens mnémotechnique en une phrase. 
  Sans markdown. Si tu trouves rien de pertinant réponds "..."`}]
  const hint = await gpt(messages, true, ct)
  return hint.choices[0]?.message.content
}


const hideLetters = (sentence, letter = "_") => sentence.replace(/\p{L}/gu, letter)
function preloadImages(urls, callback) {
  let loadedCounter = 0;
  let images = [];

  for (let i = 0; i < urls.length; i++) {
      images[i] = new Image();
      images[i].onload = () => {
          loadedCounter++;
          if (loadedCounter === urls.length) {
              callback(images);
          }
      };
      images[i].src = urls[i];
  }
}


const getTrigger = (card, props_trigger_index, ct) => {
  const model = models.find(m => m.name == card?.original_card.model)    
  let trigger_index =  props_trigger_index == 0 ?  props_trigger_index : props_trigger_index || (card?.user_card?.next_trigger || 0)
  const _trigger = model?.triggers[trigger_index]   
  const lvl = card?.user_card?.lvl || 0
  let trigger =  _trigger?.alternative?.find(a => a.lvls.includes(lvl.toString())) || _trigger

  // console.log('trigger', trigger?.name)
  if (trigger?.name == "Compréhension Orale") {
    // console.log('CO')

    // console.log('Ct cantListen', ct.cantListen)
    if (ct.cantListen) {
      trigger = _trigger
      // console.log('CHANGMEENT DE TRIGGER', trigger?.name)
    }
   
  }
  return {trigger, trigger_index}
}

const emptyUserCard = {lvl: 0, fake: true, next_date: new Date(), next_trigger: 1, triggers:
  [
    {
        "input": "comp",
        "lvl": 0,
        "next_date": new Date(),
    },
    {
      "input": "exp",
      "lvl": 0,
      "next_date": new Date(),
  },
   
]}
function splitSentence(sentence, word) {
  const words = sentence?.split(' ');

  if (words?.length <= 5) {
      return words;
  }

  let result = [];
  let combined = '';

  for (let i = 0; i < words?.length; i++) {
      if (combined.length === 0) {
          combined = words[i];
      } else {
          combined += ' ' + words[i];
      }

      if (result.length < 4) { // Allow combining more words if result has less than 4 elements
          if (words[i + 1] && combined.split(' ').length < (words.length / 5)) {
              continue;
          }
      }

      result.push(combined);
      combined = '';
  }

  if (combined.length > 0) {
      result.push(combined);
  }

  return result;
}



const layout = {
  'default': [
    'q w e r t y u i o p',
    'a s d f g h j k l',
    'z x c v b n m'
  ],
  'shift': [
    '~ ! @ # $ % ^ &amp; * ( ) _ + {bksp}',
    '{tab} Q W E R T Y U I O P { } |',
    '{lock} A S D F G H J K L : " {enter}',
    '{shift} Z X C V B N M &lt; &gt; ? {shift}',
    '.com @ {space}'
  ]
}
//const validSound = new Audio('/assets/valid_sound.mp3');


const validSound = new Howl({src: [`/assets/valid_sound.mp3`],html5: true, volume: 0.5});

// const badSound = new Audio('/assets/bad_sound.mp3');
const badSound = new Howl({src: [`/assets/bad_sound.mp3`],html5: true, volume: 0.1});
// const newSound = new Audio('/assets/valid_sound.mp3');
const newSound = new Howl({src: [`/assets/valid_sound.mp3`],html5: true, volume: 0.1});



function cleanText(text) {
  let lowercaseText = text?.toLowerCase();

  let cleanedText = lowercaseText?.replace(/[.,!?;()"'-]/g, "")

  return cleanedText;
}


const autoCorrect = false
const keyboard =  ["azertyuiop", "qsdfghjklm", "wxcvbn"] 
let n = 0

const filterTrigger = (ct, globalCards) => {
  if (ct.userWorkspace?.goal_id != "TOEIC") return globalCards
  return globalCards.map(gc => ({...gc, user_card: gc.user_card && {...gc.user_card, next_trigger: 0, triggers: [gc.user_card?.triggers[0]]} })).sort((a,b) => a.original_card?.deck_id - b.original_card?.deck_id)  
}


const Waiter = ({ time = 2, message, messageShow, barColor = "bg-purple-500/50", handleGood, custom, className, handleBad, uniqueChoice, handleUnique }) => {
  const [showButtons, setShowButtons] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {setShowButtons(true)}, time * 1000);
    return () => clearTimeout(timeoutId);
  }, [time]);


  return (
    <div className={`${className} transition-all  p-3 rounded-b-xl`}>
      <div className='text-sm  transition-all  text-slate-500 flex justify-center'>{showButtons ? messageShow || message : message || 'Essaie de trouver la phrase'}</div>
      <div className='min-h-[100px] transition-all flex items-center justify-center'>
      {!showButtons ? <div className=" rounded-fullh-[5px] w-[120px] mx-auto">
        <div className={`mt-4 ${barColor} h-[5px] rounded-full`} style={{ width: '0%', animation: `fillBar ${time}s ease-in forwards` }}></div>
      </div> : custom ? custom : uniqueChoice ?           <button onClick={handleUnique} className='button px-2 py-1 w-[100px] text-purple-500 border-purple-500 border border-b-2 rounded-xl grow' style={{ marginRight: '10px' }}>Suivant</button>
 : (
        <div className='flex w-full' style={{ marginTop: '10px' }}>
          <button onClick={handleBad} className='button bg-white/60 px-2 py-3 w-[100px] text-[#6360ffb8] border-[#6360ff6e] border-2 border-b-[4px] rounded-2xl grow' style={{ marginRight: '10px' }}>
            
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-[40px] mx-auto ">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                </svg>
                Je ne sais plus
            </button>
          <button onClick={handleGood} className='button bg-white/60 px-2 py-3 w-[100px] text-green-500 border-green-500/50 border-2 border-b-[4px] rounded-2xl grow'>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-[40px] text-green-400  mx-auto">
                    <path strokeLinecap="round" strokeLinejoin="round"d="M4.5 12.75l6 6 9-13.5" />
                  </svg>
                  Je sais
          </button>
        </div>
      )}
      </div>
    </div>
  );
};





function LearnContainer(props) {
    // console.log('LEANRN CONTAINER')
    const ct = props.context
    const navigate = useNavigate()
    const [isFliped, setIsFliped] = useState(false);
    const [userCard, setUserCard] = useState()
    const [showXP, setShowXP] = useState(false);
    const [newCard, setNewCard] = useState()

    const learnMode = ct.user?.learnMode == "B" ? "context" : "classic"

    const [showMore, setShowMore] = useState(true);
    const [showInfoLoading, setShowInfoLoading] = useState(false);
 
    //const [randomProps, setRandomProps] = useState([])
    const [imagesReady, setImagesReady] = useState(false);
    const [randomCardDeck, setRandomCardDeck] = useState([])
    const [error, setErrors] = useState(0)

    const randomProps = error > 0 ? [...randomCardDeck.filter(item => item.valid), ...randomCardDeck.filter(item => !item.valid).slice(0, 1)] : randomCardDeck;

    useEffect(() => {
      // Charger toutes les images et attendre qu'elles soient toutes prêtes
      Promise.all(
        randomProps.map(card => {
              return new Promise((resolve, reject) => {
                  let img = new Image();
                  img.onload = resolve;
                  img.onerror = reject;
                  img.src = card.img_400 || card.img?.replace('&h=200', '&h=350');
              });
          })
      ).then(() => {
          // Toutes les images sont chargées
          setImagesReady(true);
      }).catch(error => {
          console.error("Erreur lors du chargement des images", error);
      });
  }, [randomCardDeck]);

    const [showCards, setShowCards] = useState(props.showCards)
    const [masteredCards, setMasteredCards] = useState([])
    const [reviewCards, setReviewCards] = useState([])
    const [toDoCards, setToDoCards] = useState([])
    const [inputState, setInputState] = useState(0)
    const [accentWarning, setAccentWarning] = useState()
    const [showInputValidation, setShowInputValidation] = useState()
    const [speakingTerm, setSpeakingTerm] = useState()
    const [speakingContext, setSpeakingContext] = useState()
    const [indexContextReveal, setIndexContextReveal] = useState(0)
    const [showParam, setShowParam] = useState(false)
   
    const flashcardRef = useRef(null);
    const [updatedCard, setUpdatedCard] = useState()
    const [selectedWords, setSelectedWords] = useState([])
    const [selectedLetter, setSelectedLetter] = useState([])
    const [randomWords, setRandomWords] = useState()
    const [randomWordsContext, setRandomWordsContext] = useState()
    const [randomLetters, setRandomLetters] = useState()
    const [showHint, setShowHint] = useState(false)

    const [deck, setDeck] = useState()
    const [showDeck, setShowDeck] = useState(false)
    const [selectedDeck, setSelectedDeck] = useState(false)
    const [newPoint, setNewPoint] = useState(0)
    const [hintMode, setHintMode] = useState()
    const [hintChange, setHintChange] = useState()
    const [showBack, setShowBack] = useState(true)




    const choicesExpression = [
      {
      "id": "B",
      "name": "Avec contexte",
      "desc": "Cela te permet d'être plus efficace sur ta capacité à t'exprimer et à créer des phrases. Conseillé si tu es familier avec la langue"
    },
    { 
      "id": "A",
      "name": "Sans contexte",
      "desc": "Travaille uniquement sur les mots. Conseillé si tu débutes."
    }
    ]



    const showParams = showParam && <div className='absolute text-white p-8 bg-black/80 top-0  backdrop-blur-[10px] left-0 right-0 bottom-0'>
     
     <div className='absolute top-5 right-5' onClick={() => { setShowParam(false)}}><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
</svg>
</div>
     <div className='mx-auto max-w-[500px] h-full -mt-12 flex flex-col justify-center items-center'>
     
  
      <div className='fredoka px-2 bg-white/20 rounded-xl'>Mode d'apprentissage</div>
      <div className='text-xl fredoka mb-8'>Expression</div>
      {choicesExpression.map(e => <div onClick={() => {
          const newLearnMode = ct.user?.learnMode == "A" ? "B" : "A";
          ct.setUser(prev => ({...prev, learnMode: newLearnMode}))
          updateDoc(doc(db, 'users', ct.user.id), {learnMode: newLearnMode});
      }} className={`${ct.user?.learnMode == e.id ? "bg-amber-800 border-yellow-500" : "border-white/50 opacity-80"} b transition-all px-4 w-full py-1 border border-b-4 rounded-xl mt-2`}>
        <div className='text-lg font-semibold fredoka'>{e.name}</div>
        <div className='text-white/70'>{e.desc}</div>
        </div>)}
      </div>
     

    </div>



 
    const _hint_text = toDoCards[0]?.original_card?.hint
    const _hint_img = toDoCards[0]?.original_card?.hint_img 
    const hint_text = toDoCards[0]?.user_card?.hint_text || hintChange?.hint_text
    const hint_img = toDoCards[0]?.user_card?.hint_img || hintChange?.hint_img
    const hint_info = toDoCards[0]?.user_card?.hint_info || hintChange?.hint_info

    const hasHint = (hint_text || hint_img)
    const hintAction = (_hint_text || _hint_img) && !hint_info 
    const hintCard = _hint_text && <div id="cmoi" onClick={(e) => {e.stopPropagation()}} className='absolute z-[999] rounded-2xl flex flex-col p-6 fredoka text-white bg-gradient-to-b from-pink-500 to-pink-600 text-center top-0 bottom-0 left-0 right-0'>
      <div className=''>Astuce mnémotechnique</div>
      <div className='grow flex flex-col justify-center justify-center text-center' >
        {_hint_img && <img className='rounded-md mx-auto w-[80%] object-cover mb-4' src={_hint_img} />}
        {_hint_text}
      </div>
      <div className='flex gap-3 justify-between '>
        <div className='px-2 py-2 rounded-xl border-2 border-b-[5px] border-white text-white' onClick={() => {setHintChange({hint_info: "refused"}); setHintMode(false)}}>Supprimer</div>
        <div className='px-2 py-2 rounded-xl lg:hover:scale-110 border-2 border-b-[5px] bg-white border-pink-600 text-pink-500 grow'  onClick={() => {
          setHintChange({hint_info: "global", hint_text: _hint_text || null, hint_img: _hint_img || null});
          setHintMode(false); 
          //updateDoc(doc(db, "user_cards", toDoCards[0]?.user_card?.id), {hard_exp: true})
          }} >Ajouter</div>
        </div>
      </div>

    const _hint =  hintMode ? hintCard : hasHint ? 
    <div className='px-3 py-1 pr-1 rounded-xl text-sm bg-pink-500 text-white mt-1 relative flex items-center justify-between'>
       {hint_text}
    {hint_img ? <img className='rounded-xl h-[100px] w-[100px] object-cover' src={hint_img} /> : <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-[60px] h-[50px] flex items-center justify-center p-3 rounded-xl bg-white/20">
        <path strokeLinecap="round" strokeLinejoin="round" d="M12 18v-5.25m0 0a6.01 6.01 0 001.5-.189m-1.5.189a6.01 6.01 0 01-1.5-.189m3.75 7.478a12.06 12.06 0 01-4.5 0m3.75 2.383a14.406 14.406 0 01-3 0M14.25 18v-.192c0-.983.658-1.823 1.508-2.316a7.5 7.5 0 10-7.517 0c.85.493 1.509 1.333 1.509 2.316V18" />
      </svg>}
   
    </div> 
    :
     hintAction ? <div className='flex justify-center items-center -mb-2'><div onClick={(e) => {setHintMode(_hint_text); e.stopPropagation(); console.log('test', hintMode)}} className='cursor-pointer self-center align-center justify-center rounded-xl flex text-white px-3 text-sm mx-auto lg:hover:scale-110 bg-pink-500 py-1 my-3 mb-1'>Voir l'astuce <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 ml-2 rounded-full">
     <path strokeLinecap="round" strokeLinejoin="round" d="M12 18v-5.25m0 0a6.01 6.01 0 001.5-.189m-1.5.189a6.01 6.01 0 01-1.5-.189m3.75 7.478a12.06 12.06 0 01-4.5 0m3.75 2.383a14.406 14.406 0 01-3 0M14.25 18v-.192c0-.983.658-1.823 1.508-2.316a7.5 7.5 0 10-7.517 0c.85.493 1.509 1.333 1.509 2.316V18" />
   </svg></div></div> : <div></div>


    useEffect(() => { 
      const cardDeck = ct.decks?.find(d => d.id == toDoCards[0]?.original_card?.deck_id )
      setSelectedDeck(cardDeck)
      setDeck(cardDeck?.parent_id ? ct.decks.find(d => d.id == cardDeck?.parent_id) : cardDeck)   
    }, [toDoCards])

    useEffect(() => {  
      if (!deck) return
      if (ct.userDecks?.find(ud => ud.deck_id == deck.id)?.read) {} 
      else {
        if(deck?.presentation_mode == "Grammar") setShowDeck(true)
      }

    }, [deck])
      
    const init = () => {
      console.log('init')
        setErrors(0)
        setShowInputValidation()
        setInputState(0)
        setShowInfoLoading(false)
        setShowMore(true)
        setSelectedWords([])
        setSelectedLetter([])
        setShowHint(false)
        setNewCard()
        setHintMode(false)
        setIndexContextReveal(0)
        setHintChange()
        setUserCard(emptyUserCard)
        setAccentWarning(false)
      } 

 

      function removeExtraSpaces(text) {
        if (!text) return ''
        // Remplace les doubles (ou plus) espaces par un seul espace
        let cleanedText = text?.replace(/\s{2,}/g, ' ');
    
        // Supprime les espaces avant la ponctuation (comme .,?!)
        cleanedText = cleanedText?.replace(/\s+([.,?!])/g, '$1');
    
        // Supprime les espaces en début et fin de texte s'il y en a
        cleanedText = cleanedText?.trim();
    
        return cleanedText;
    }

    useEffect(() => {
      if (!toDoCards[0]?.user_card) {
   
        if (!toDoCards[0]?.original_card?.contexts?.length) return
  
        const correct = removeExtraSpaces(toDoCards[0]?.original_card?.contexts[0]?.text || context?.trim().split(' ').join(' '))
        const answer = removeExtraSpaces([...selectedWords].map(w => w.word).join(' '))


        if (correct == answer) {
         // validSound.play() 
         const the_word = toDoCards[0]?.original_card?.term
         const nb_of_words = the_word?.split(' ')?.length
         const pass_the_writting = ct.userWorkspace?.goal_id == "TOEIC" || nb_of_words > 2 || ct.userWorkspace?.goal_id == "perfect"
       
         const timeoutId = setTimeout(() => {setInputState(pass_the_writting ? 3 : 2)}, 500);
        
         speak({text: card.term , lang: ct.workspace.lang})
       
          return () => clearTimeout(timeoutId);
    
        }
      
        if (answer?.length == correct?.length) {
          //bug sound
          //badSound.play() 
          let result = [];
          const sortedWords = selectedWords
          const correctWords = correct.split(' ')
   

          let currentIndex = 0;

          for (let entry of sortedWords) {
              const wordsInEntry = entry.word.split(' ');
              
              // Vérifiez si les mots actuels correspondent à la position correcte
              if (wordsInEntry.every((word, i) => word === correctWords[currentIndex + i])) {
                  result.push(entry);
                  currentIndex += wordsInEntry.length;
              } else {
                  break;
              }
          }  
          const timeoutId = setTimeout(() => {setSelectedWords(result)}, 800);
          return () => clearTimeout(timeoutId);
          
         
         
          
        }
       
        return
      }
      if (selectedWords.length == toDoCards[0]?.original_card?.term?.trim().replace(/[.?!,]/g, '')?.split(' ').length) {
        const correct = toDoCards[0]?.original_card?.term?.trim().replace(/[.?!,]/g, '')?.split(' ').join(' ')
        const answer = [...selectedWords].map(w => w.word).join(' ')
    
        if (correct == answer) {
          //bug speak
          setIsFliped(true); 
          validDB(true, false, false); 
        } else {
          //bug speak
          setIsFliped(true); 
          validDB(false, false, false); 
          setErrors(2)
        }
      }
    }, [selectedWords])



    useEffect(() => {
      const correct = toDoCards[0]?.original_card?.term?.trim().toLowerCase()
      const answer = [...selectedLetter].map(w => w.letter).join('').toLowerCase()


      if (answer.length == toDoCards[0]?.original_card?.term?.trim().length) {
       
        if (correct == answer) {
           // bug speak
          lauchSpeak({text: context, lang: ct.workspace.lang, upload: true})
          setIsFliped(true); 
          if (error == 0) validDB(true, false, false); 
        } else {
         // setIsFliped(true); 
         // bug speak
         
          setSelectedLetter([])
          validDB(false, false, false); 
          setErrors(2)
        }
      }
    }, [selectedLetter])



    let card = toDoCards[0]?.original_card
    let term = toDoCards[0]?.original_card?.term;

    


    const rewind =  (selectedWords.length > 0 || selectedLetter.length > 0) && <div className='flex z-20 absolute right-4 -top-[50px]'><div className='rounded-full b border-b-4 flex items-center justify-center border w-[36px] h-[36px] bg-white text-slate-400' onClick={() => {setSelectedWords([]); setSelectedLetter([])}}><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
    <path strokeLinecap="round" strokeLinejoin="round" d="M21 16.811c0 .864-.933 1.405-1.683.977l-7.108-4.062a1.125 1.125 0 010-1.953l7.108-4.062A1.125 1.125 0 0121 8.688v8.123zM11.25 16.811c0 .864-.933 1.405-1.683.977l-7.108-4.062a1.125 1.125 0 010-1.953L9.567 7.71a1.125 1.125 0 011.683.977v8.123z" />
  </svg>
  </div></div>


const lauchSpeak = async (obj, type=null) => {
  // type == "term" ? setSpeakingTerm(true) : setSpeakingContext(true); 
  // const isFinised = await speak(obj, ct)
  // if (isFinised) type == "term" ? setSpeakingTerm(false) : setSpeakingContext(false); 
  setSpeakingContext(true); 
  await speak({text: card.term + "... . ..." + card?.context , lang: ct.workspace.lang})
  setSpeakingContext(false)
}

const play2sounds = async (card) => {
  // setSpeakingTerm(true)
  // await speak({text: card?.term, lang: ct.workspace.lang})
  // setSpeakingTerm(false)
  // setSpeakingContext(true); 
  // await speak({text: card?.contexts[0]?.text || context, lang: ct.workspace.lang})
  // setSpeakingContext(false)


   setSpeakingContext(true); 
   await speak({text: card.term + "... . ..." + card?.context, lang: ct.workspace.lang})
   setSpeakingContext(false)
}



    const initAnwser = (toDoCards) => {
      const card = toDoCards[0]?.original_card
      setRandomWords(shuffleArray(card?.term?.trim()?.replace(/[.?!,]/g, '')?.split(' ')))
      if (card?.contexts) setRandomWordsContext(shuffleArray(splitSentence(card?.contexts[0]?.text || context?.trim(), card?.term?.trim())))
   
      const termLetters = card?.term?.trim()
      let result = [];
      const letterLength = termLetters?.length > 9 ? 4 :termLetters?.length > 4 ? 3 : 2
      for (let i = 0; i < termLetters?.length; i += letterLength) {
        result.push(termLetters.substr(i, letterLength));
    }
      console.log('result', result)
      setRandomLetters(shuffleArray(result))
      const cardsInOrder = [...ct.cards || [], ...ct.userCards.map(lc => lc.content).filter(c => c != undefined) || [], ...ct.nextCards || []]

      const cardOutterDeck = pickRandomElements(cardsInOrder.filter(c => c.id != card?.id && c?.img != null && c?.img != ""), 3)
      let otherCards = 
        cardOutterDeck
        .concat()
        cardOutterDeck.slice(0, 3).map(c => ({...c, valid: false})) 

      setRandomCardDeck(shuffleArray(otherCards?.concat({...toDoCards[0]?.original_card, valid: true})))
      
    }
  

    function rearrangeCards(cards) {
      for (let i = 1; i < cards.length; i++) {
        if (cards[i].original_card.id === cards[i - 1].original_card.id) {
          // Find the next card with a different original_card.id
          let j = i + 1;
          while (j < cards.length && cards[j].original_card.id === cards[i].original_card.id) {
            console.log('loop')
            console.log('j',j )
            console.log('cards.length',cards.length )
            j++;
          }
    
          // If we found a card with a different original_card.id, swap it with the current one
          if (j < cards.length) {
            [cards[i], cards[j]] = [cards[j], cards[i]];
          }
        }
      }
      return cards;
    }


    const getContextForCard = (card) => {
      let context = card.contexts && card.contexts[0]?.text 

      if (card.context) {
        console.log('pas de context', card.term)
      }
      // ajouter la logique du context 
     
      const contextWithBracket = addBracket(context, card?.term)
      const containsBrackets = /\[.*?\]/.test(contextWithBracket);
      let context_trad = card?.contexts && card?.contexts[0]?.trad
      const context2 = card?.more?.definitions?.[0]?.ex?.[0]?.text
      const trad2 = card?.more?.definitions?.[0]?.ex?.[0]?.trad_fr
    
      
      if (!containsBrackets) {
        console.log('CHANGEMENT DE CONTEXTE !!', card.term)
        context = context2
        context_trad = trad2
        
      }
      return {...card, context: context, context_trad: context_trad, prout: true}
    }


    const reset = () => {
      setNewPoint(0)
    }
    

    useEffect(() => {

      setMasteredCards([])
      setReviewCards([])
      const globalCards = ct.showCards.map(card => ({original_card: getContextForCard(card), user_card: ct.userCards.find(userCard => userCard.card_id === card.id)}))
      globalCards.sort((a, b) => {
        return a.user_card?.next_trigger - b.user_card?.next_trigger;
      });
      // faire en sorte que les globalCards.original_card.id identique ne se suivent pas
      // faire en sorte de reduire la date d'expression pour les stacked
      setToDoCards(filterTrigger(ct, rearrangeCards(globalCards)))
      setShowCards(ct.showCards)
      setInputState(0)  
     
      init()
      initAnwser(globalCards)
      

    
    }, [props.showCards, ct.showCards])


    
  

    const validDB = async (valid, archived = false, sound = true) => {

      console.log('valid DB 🟢🟢🟢🟢', toDoCards)

     
      if(!toDoCards[0].user_card) {    
        console.log('pas de usercard')
        
        if (valid) {sound && newSound.play()} else {sound && badSound.play()}
        let opt = deck?.presentation_mode != "Dialogue" ? {setStatus: true} : {name: "valid comp", setStatus: true}
        if (deck?.presentation_mode == "Grammar") {opt = {isValid: valid}}
        if (hintChange) {opt = {...opt, hint: hintChange, setStatus: true}}
        if (archived) {
          
          opt = {...opt, hint: hintChange, archived: true, setStatus: true}
        }
        console.log('before learn')
        const newUserCard = await learn(ct, toDoCards[0].original_card, opt); 
        const _udpatedcard = {...toDoCards[0], user_card: newUserCard}
        setUpdatedCard(_udpatedcard)
        console.log('_udpatedcard', _udpatedcard)
        return _udpatedcard

      } else {
       if (valid) { 
            sound && validSound.play() 
        }
          else {
            sound &&  badSound.play()
        }
        let opt
        if (hintChange) {opt = {...opt, hint: hintChange}}
        if (toDoCards[0].user_card?.stacked) {opt = {...opt, unstack: true, setStatus: true}}

        const udpatedUserCard = await validCard(ct, toDoCards[0].user_card, valid, trigger_index, opt)
        const _udpatedcard = {...toDoCards[0], user_card: udpatedUserCard}
        setUpdatedCard(_udpatedcard)
        return _udpatedcard      

      }
    }


    const playNextSound = () => {
      const next_trigger = toDoCards?.length > 1 && getTrigger(toDoCards[1], props.trigger_index, ct).trigger
            console.log('next_trigger', next_trigger)
            
            if (next_trigger?.id == "co") play2sounds(toDoCards[1]?.original_card)
    }

    const validFlashCard = async (valid, updatedCard) => {
     
      console.log('updatedCard', updatedCard)
      setIsFliped(false)
      let _toDoCards = toDoCards.map(c => c.original_card.id == updatedCard?.original_card?.id ? updatedCard : c)
      let _reviewCards = reviewCards.map(c => c.original_card.id == updatedCard?.original_card?.id ? updatedCard : c)
      const _masteredCards = masteredCards
      _toDoCards.shift()
    
      if (valid) {  

        console.log('VALIIIIID')
    

        if (updatedCard?.user_card?.archived) {
        
          _masteredCards.push(toDoCards[0])
         
          _toDoCards.filter(c => c.original_card.id == updatedCard.original_card.id).forEach((toDoCard) => {
            _masteredCards.push(toDoCard)
          })
          // on ajoute la maitrise pour les prochaines occurences 
          _toDoCards = _toDoCards.filter(c => c.original_card.id != updatedCard.original_card.id)
          // on supprime les cartes de la todolist (en gros on valide toute les exo de la carte)
        } else {
          _masteredCards.push(toDoCards[0])
        }
       
        setMasteredCards(_masteredCards)
        const newDate = new Date();
        if (!toDoCards[0]?.user_card?.next_date) {
    
          setNewPoint(newPoint + 1)
        }
       
        newDate.setSeconds(newDate.getSeconds() + 40)
        _reviewCards = [..._reviewCards.map(c => c == toDoCards[0] ? updatedCard : c).filter(c => c.user_card.next_date < newDate)]
       // _reviewCards = [..._reviewCards.filter(c => c != toDoCards[0])]
  
        console.log(`mastered ${_masteredCards?.length} showCard ${ct.showCards.length} _reviewCards ${_reviewCards.length} `)
        if ((_masteredCards?.length >= ct.showCards.length ) || ct.showCards.length == 1) {
        
         
          // if (ct.cardsToPlace?.length > 0) {
         
          //   navigate('/forest')
       
          // }

          if (ct.lessonOfTheDay == 1) {
            console.log('mission du jour terminé')

            const today = new Date();
            const dateOnly = new Date(today.getFullYear(), today.getMonth(), today.getDate());
        
            ct.setUserWorkspace(u => ({...u, last_dm: dateOnly }))
            updateDoc(doc(db, "user_workspace", ct.userWorkspace.id), {last_dm: dateOnly});

            ct.setLessonOfTheDay(2)
            ct.setShowCards(props.deckData.nextCards)
            reset()
           
          
          } else {
            ct.showCards.length != 1 && ct.setShowPop('dailyMission')
            ct.setShowCards();
            ct.setLessonOfTheDay()
          }
         
          
      

        }
      } else {
     
        const isIn = _reviewCards.find(c => c.original_card.id == updatedCard.original_card.id)
    
        !isIn && _reviewCards.push(updatedCard)
       
  
        //_toDoCards.push(updatedCard);

     
       // !_reviewCards.includes(updatedCard) && _reviewCards.push(updatedCard)
        
      }
      if (_toDoCards.length == 0) {
     
        _toDoCards = _reviewCards
        
      }


 
      
      setReviewCards(_reviewCards)
      setShowBack(false)
      setToDoCards(filterTrigger(ct, _toDoCards))
      init()
      setInputState(0)
      initAnwser(_toDoCards)

      const timeoutId = setTimeout(() => {
        setShowBack(true)
        stopSound()
      }, 100);
      return () => clearTimeout(timeoutId);
      
     

      
     
  
      
      

     
      //ct.setShowCards();
    }
   
    const timeoutId = useRef(null); // Stocke l'ID du timeout

  


    function extractTextWithTags(input) {
      const temp = document.createElement("div");
      temp.innerHTML = input;
      return temp.innerText && temp.innerText != undefined ? temp.innerText : null;
    }
   

    const index_card = showCards?.findIndex(c => c.id == toDoCards[0]?.original_card?.id) // Jamais commencer par audio
    const trigger = getTrigger(toDoCards[0], props.trigger_index, {...ct, cantListen: toDoCards[0]?.user_card?.stacked ? true : index_card == 0 ? true : ct.cantListen}).trigger
    const trigger_index = getTrigger(toDoCards[0], props.trigger_index, ct).trigger_index
    

  


   
   

    useEffect(() => {



      if (isFliped == false) return
     
      setUserCard(toDoCards[0]?.user_card?.next_date ? toDoCards[0]?.user_card : emptyUserCard)
   
    

      // const timeoutId = setTimeout(() => {
        const _nextUserCard = nextUserCard(toDoCards[0]?.user_card, error == 0)
     
        setUserCard(_nextUserCard)
      // }, 700);
      return () => clearTimeout(timeoutId);
     

    }, [isFliped])
   
    const renderText = (text) => {
      return <div key={text}><SuperEditor content={text}/></div>
    }


    let mode = trigger?.input || "classic"
    if (!toDoCards[0]?.user_card) {
      mode = "new"
    }
    
    // let letters = shuffleArray(term?.split(''));

    const skipBtn = ct.userWorkspace.goal_id == "perfect" || toDoCards[0]?.user_card?.ud_id ? <div></div> : <div onClick={async (e) => {
      console.log('test', e)
      e.stopPropagation(); 
      const updatedCard = await validDB(true, true); 
      validFlashCard(true, updatedCard); 
}} className=' text-md mb-2 rounded-full border-2 border-black/20 border-b-4 px-3 py-1 flex gap-2 opacity-50 items-center'>Ne pas apprendre <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
<path strokeLinecap="round" strokeLinejoin="round" d="M3 8.689c0-.864.933-1.406 1.683-.977l7.108 4.061a1.125 1.125 0 0 1 0 1.954l-7.108 4.061A1.125 1.125 0 0 1 3 16.811V8.69ZM12.75 8.689c0-.864.933-1.406 1.683-.977l7.108 4.061a1.125 1.125 0 0 1 0 1.954l-7.108 4.061a1.125 1.125 0 0 1-1.683-.977V8.69Z" />
</svg>

</div>


    const showInput = () => {
      const defs = card.more?.definitions?.map(def => def.trad_fr).join(', ');

      
        // let shuffled = shuffleArray(letters)
    
        if (card.model == "grammar")
        {
         
         return 
        }
       

        if (mode == "new") {
         
         
          return  <div className=" relative flex items-center justify-center p-4" onClick={(e) => {e.stopPropagation()}}>
          {inputState == 0 && skipBtn}
          {inputState == 1 && <>
              <div className='flex gap-1 mt-2 flex-wrap justify-center'>
              <div className='flex gap-2 flex-wrap justify-center'>{randomWordsContext?.map((word, i) => <div 
              onClick={() => {
                if (!selectedWords?.map(w => w.i).includes(i)){
                  
                  setSelectedWords([...selectedWords, {word, i}])
                  speak({text: word, lang: ct.workspace.lang, upload: true}, ct)}

               
                 
                }} className={`border b transition border-b-4 rounded-xl ${randomWordsContext.length > 7 ? "px-[6px] py-[3px] text-sm" : "px-[12px] py-[6px]"}  bg-white ${selectedWords?.map(w => w.i).includes(i) ? "border-slate-100 text-slate-100 " : cleanText(word) == cleanText(toDoCards[0]?.original_card?.term) ? "text-amber-500 border-slate-400" : "border-slate-400"}`}>{word}</div>)}</div></div>
              <div className=''>{rewind}</div>
              </>  }
        

        {inputState == 3 && !hintMode && <div onClick={
          async (e) => { 
            e.stopPropagation(); 
            const updatedCard = await validDB(true); 
            validFlashCard(true, updatedCard); 
            playNextSound()
            }} className='mx-auto cursor-pointer no-flip absolute z-10 -bottom-[20px] flex items-center gap-3 px-5 py-2 border border-b-4 border-indigo-700 right-0 rounded-full bg-indigo-500 text-shadow  text-white font-semibold text-xl'>
                Continuer  →
              {/* <svg width="19" height="19" viewBox="0 0   19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13.9697 14H10.9697V16H13.9697V1 9H15.9697V16H18.9697V14H15.9697V11H13.9697V14ZM8.96973 15C8.96973 11.69 11.6597 9 14.9697 9C15.7397 9 16.4697 9.15 17.1397 9.41C18.5997 4.9 17.6697 0.3 17.6697 0.3C17.6697 0.3 16.2697 0 14.1697 0C8.66973 0 -1.43027 2.1 0.169726 17.8C1.26973 17.9 2.36973 18 3.36973 18C5.72973 18 7.70973 17.69 9.37973 17.15C9.11973 16.5 8.96973 15.76 8.96973 15ZM3.96973 14C3.96973 14 3.96973 4 13.9697 4C13.9697 4 7.96973 6 3.96973 14Z" fill="white"/>
              </svg> */}
          </div> }
      </div>
        }

        if (mode == "classic") {
            return  <div className="bottom  no-flip">
                        <textarea id="input-bottom" placeholder="Ta réponse ici" className="input-bottom no-flip" onClick={(e) => e.stopPropagation()}></textarea>
                        <div id="switch" className="btn-green no-flip" onClick={(e) => {e.stopPropagation(); setIsFliped(true); document.querySelector('#input-bottom').value = ""; toDoCards[0]?.original_card?.contexts && speak({text: toDoCards[0]?.original_card?.context, lang: ct.workspace?.lang}, ct)
}}>
                            Voir la réponse →
                        </div>
                    </div>
        } 
        if (mode == "exp") {
           
            if (card.term.split(' ').length > 3 ) {

         
           

             return  <div className="bg-white w-full p-4 no-flip text-center rounded-xl overflow-hidden ">
              
                {inputState == 0 && <div className={'flex h-[200px] justify-between w-full items-center'}>
              <div className="  text-white  w-1/2  h-full  text-md no-flip text-purple-400 p-2 pr-1" onClick={(e) => {e.stopPropagation(); setInputState(1) }}>
                <div className='b flex flex-col h-full justify-center bg-purple-100  items-center border rounded-xl border-2 border-purple-200'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-12 text-purple-400 h-12 mx-auto ">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                </svg>
                 <div className='text-purple-400 mt-4'>Je ne sais plus </div>
                 </div>
              </div>
               <div className="text-white  w-1/2  bg-white h-full  text-md  no-flip text-green-500 p-2 pl-1 " onClick={(e) => {e.stopPropagation(); setInputState(1)}}>
               <div className=' b flex flex-col h-full justify-center bg-green-100 items-center rounded-xl border-2 border-green-200'>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-12 text-green-400 h-12 mx-auto">
                    <path strokeLinecap="round" strokeLinejoin="round"d="M4.5 12.75l6 6 9-13.5" />
                  </svg>
                  <div className='text-green-500 mt-4'>Je sais </div>
                  </div>
              </div>
              </div>}
              {inputState == 1 &&
              <>
                        <div className='flex gap-1 mt-2 flex-wrap justify-center'>
                        <div className='flex gap-2 flex-wrap'>{randomWords.map((word, i) => <div onClick={() => {
          
                          setSelectedWords([...selectedWords, {word, i}])} } className={`border-2 b lg:hover:border-blue-500  lg:hover:text-blue-500 transition  border-b-4 rounded-xl px-2 py-1 bg-white ${selectedWords.map(w => w.i).includes(i) ? "border-slate-100 text-slate-100 " : "text-purple-500 border-slate-400"}`}>{word}</div>)}</div></div>
                        <div onClick={() => {setIsFliped(true); validDB(false); setErrors(2);     toDoCards[0]?.original_card?.contexts && speak({text: toDoCards[0]?.original_card?.context, lang: ct.workspace?.lang}, ct)
}} className='px-2 py-1 mb-4 text-slate-500 border-2 border-b-4 rounded-xl b lg:hover:border-blue-500 lg:hover:bg-blue-50 mt-4'>Voir la réponse →</div>
                        </> }
            </div>

            }

            if (learnMode == "context" ){
             if (inputState == 99) return <Waiter consign="coucou" message="Prononce la phrase dans ta tête"  uniqueChoice="Suivant" handleUnique={() => {setInputState(1)}} handleBad={() => console.log('bad')} handleGood={() => {console.log('good'); setInputState(1)}}  />
             if (inputState == 0) return <div className='bg-white w-full h-[60px] justify-center flex-wrap flex  opacity-60 text-sm p-4 no-flip items-center rounded-3xl rounded-t-none transition'>
              <div className='flex gap-2 border border-b-2 border-purple-400 -mt-10 justify-center px-2 py-1 rounded-xl bg-purple-300 text-purple-900'>
                <BrainSVG />
                Prononce la phrase dans ta tête</div>
              <div className='text-slate-500 text-xs mt-1 w-full'>Puis appuie sur les <span className='border border-purple-300/90 rounded-md py-1 px-1 bg-purple-100/50 '>rectangles</span> pour vérifier ta réponse</div>
              </div>

            }

            return  <div onClick={(e) => {e.stopPropagation()}} className={`bg-white w-full  h-[120px] p-4 no-flip text-center rounded-xl transition`}>
              
                    <div className='flex gap-1 -mx-2 relative flex-wrap justify-center'>
                 
                    {rewind}
                    {showHint == 2 && randomLetters.map((letter, i) => <div onClick={() => {
                       const correct = toDoCards[0]?.original_card?.term?.trim().replace(/[.?!,]/g, '')?.split(' ').join(' ')
                        const answer = [...selectedLetter, {letter, i}].map(w => w.letter).join('').toLowerCase()
                       console.log('correct', correct)
                       console.log('answer', answer)
                       if (correct == answer) {

                        play2sounds(toDoCards[0].original_card)
                       }
                      !selectedLetter.map(w => w.i).includes(i) && setSelectedLetter([...selectedLetter, {letter, i}])}} 
                      className={`border-2 b lg:hover:border-blue-500 lg:hover:text-blue-500 transition border-b-4 rounded-xl px-3 min-w-[36px] py-1 bg-white  ${selectedLetter?.map(w => w.i).includes(i) ? "border-slate-100 text-slate-100 " : "text-purple-500 border-purple-500"}`}
                      >{letter}</div>) }         
                    </div>
                    <div className='flex justify-center gap-2 mt-4 text-center'>
                      {!showHint  && <div onClick={() => {
                        setShowHint(true); 
                        validDB(false); 
                        setErrors(1); 
                        //setSelectedLetter([{i: -1, letter: toDoCards[0].original_card.term.split('')[0]}])
                      }} className={`px-2 items-center py-1 mb-4 flex justify-center gap-1   text-center border-2 grow  border-b-4  max-w-[200px] rounded-xl b lg:hover:border-blue-500  lg:hover:bg-blue-50 ${currentLvlTrigger > 3 ? "text-[#ce4bd0] border-[#ce4bd0] bg-[#ce4bd033]" : "text-[#6866ff] border-[#9593ff] bg-[#e5d8ff33]" }`}>Voir un indice<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 18v-5.25m0 0a6.01 6.01 0 001.5-.189m-1.5.189a6.01 6.01 0 01-1.5-.189m3.75 7.478a12.06 12.06 0 01-4.5 0m3.75 2.383a14.406 14.406 0 01-3 0M14.25 18v-.192c0-.983.658-1.823 1.508-2.316a7.5 7.5 0 10-7.517 0c.85.493 1.509 1.333 1.509 2.316V18" />
                      </svg>
                          </div>}

                          {(showHint == 1) && <div onClick={() => {
                            setShowHint(2)
                          
                              validDB(false); 
                              setErrors(1); 
                            
                            }} className='px-2 text-[#ce4bd0] border-[#ce4bd0] bg-[#ce4bd033]  flex justify-center items-center py-1 rounded-xl border-2 gap-2 mx-auto max-w-[200px] text-center border-b-4 grow '>Coup de pouce <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-5">
  <path strokeLinecap="round" strokeLinejoin="round" d="M9.813 15.904 9 18.75l-.813-2.846a4.5 4.5 0 0 0-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 0 0 3.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 0 0 3.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 0 0-3.09 3.09ZM18.259 8.715 18 9.75l-.259-1.035a3.375 3.375 0 0 0-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 0 0 2.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 0 0 2.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 0 0-2.456 2.456ZM16.894 20.567 16.5 21.75l-.394-1.183a2.25 2.25 0 0 0-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 0 0 1.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 0 0 1.423 1.423l1.183.394-1.183.394a2.25 2.25 0 0 0-1.423 1.423Z" />
</svg>
</div>}

                      {/* {toDoCards?.[0]?.user_card?.hard_exp && <div onClick={() => {setIsFliped(true); validDB(false); setErrors(2); toDoCards[0]?.original_card?.contexts && speak({text: toDoCards[0]?.original_card?.term + "... . ..."+ toDoCards[0]?.original_card?.context, lang: ct.workspace?.lang}, ct)
                      }} className='no-last-time px-2 py-1 mb-4 text-slate-500 border-2 border-b-4 rounded-xl b grow lg:hover:border-blue-500 lg:hover:bg-blue-50'>Passer  →
                      </div>} */}
                    </div>
            </div>
        }

        if (mode == "comp") {

            const hasImg =  (card.img || card.img_400)
            
            return  <div className={`rounded-xl transition-all  no-flip p-0 bg-white  `}>

           {inputState == 0 && <Waiter className={"bg--[#bbffe3]"} barColor={trigger.id == "co" ? 'bg-blue-500': 'bg-green-500'}  message={<div onClick={() => ct.setShowInfo({title: "Astuce Neuroscience", icon:  <BrainSVG  />,classContent: "bg-[#a62acc] text-white", 
    content: neuroHints.whyImaginePerception})} className={`${trigger.id == "co" ? "bg-blue-200 text-blue-500":  "bg-green-200 text-green-500"} flex gap-2  items-center -mt-8 z-10  justify-center mx-auto px-2 py-1 rounded-xl`}><BrainSVG  />Imagine l'image dans ta tête</div>} handleBad={() => {setInputState(1)}} handleGood={() => {setInputState(1)}}  />}
              
           
             
            
              {inputState == 1 && <div className={` ${error > 0 ? "h-[140px]": "h-[280px]"}  p-2 bg-white bg-white rounded-xl grid grid-cols-2  gap-2 mx-auto `}>
                 
                    {imagesReady && randomProps?.map((card, index) => <div className={`relative overflow-hidden flex items-center justify-center cursor-pointer border-2 rounded-xl border-b-[5px]  ${showInputValidation == index && error == 0 ? card.valid ? "border-green-400  border-b-[5px]" : "border-red-400 border-b-[5px]": ""}`}>
                      {hasImg && <img src={card.img_400 || card.img?.replace('&h=200', '&h=350')} 
                        onClick={(e) => {
                            e.stopPropagation(); 
                            card.valid && handleAddXp(e)
                        
                            setShowInputValidation(index); 
                            card.valid && toDoCards[0]?.original_card?.contexts && speak({text: toDoCards[0]?.original_card?.context, lang: ct.workspace?.lang}, ct)
                          
                            card.valid && setTimeout(() => {
                              setIsFliped(true)
                              validDB(error == 0 ? true : false)
                            }, [500]);
                            !card.valid && setErrors(error+1)
                            }}
                        className={`w-full h-full object-cover border-2 border-b-4 border-blue-500 rounded-xl `} />}

                        <div className={`${hasImg ? "absolute novisible show-after-2s bg-black/60 text-slate-200" : "h-full w-full flex items-center justify-center text-slate-500"}  bottom-0 rounded-b-xl   left-0 right-0 pb-1  `}  onClick={(e) => {
                            e.stopPropagation(); 
                            setShowInputValidation(index); 
                            if (card.valid) {
                              validSound.play()
                              toDoCards[0]?.original_card?.contexts && speak({text: toDoCards[0]?.original_card?.context, lang: ct.workspace?.lang},ct)

                              setTimeout(() => {
                                setIsFliped(true)
                                validDB(error == 0 ? true : false, false, false)
                              }, [500]);
                            }
                            !card.valid && setErrors(error+1)
                            }}>{ capitalizeFirstLetter(card.trad || defs.split(',')?.[0])}</div>
                        </div>)}
                   

                
                </div>}

         
           
            </div>

        }

    }


    const handleChange = (e, answer, bg ) => {
    
      const val = e.target.value?.toLowerCase().trim();
      const goodWithoutAccent = val !== answer && comparerSansAccents(val || "", answer || "");

      console.log('val', val)
      console.log('answer', answer)
  
      if (val === answer || goodWithoutAccent) {
          validSound.play();
          e.target.blur()
          e.target.classList.add('text-blink')
      } else {
        const bg = document.querySelector('#bganswer')
        if (val.length === answer.length) {
          bg.classList.add('wizz');
          setTimeout(() => {
            bg.classList.remove('wizz');
          }, 300); // Durée de l'animation
      }}
  }
   
  const xpRef = useRef(null);

  const handleAddXp = (e) => {

    const container = e.currentTarget;

    // Obtenir les coordonnées du clic par rapport à l'élément conteneur
    const rect = container.getBoundingClientRect();
    const offsetX = e.clientX - rect.right;
    const offsetY = e.clientY - rect.top;

    console.log('rect left', rect.left)
    console.log('rect top', rect.top)

    // Obtenir les dimensions de l'élément conteneur
    const containerWidth = rect.width;
    const containerHeight = rect.height;

    // Calculer les positions relatives en pourcentage
    const leftPercentage = (offsetX / containerWidth) * 100;
    const topPercentage = (offsetY / containerHeight) * 100;

    console.log('Left:', leftPercentage + '%', 'Top:', topPercentage + '%');
    if (xpRef.current) {
      xpRef.current.style.left = `${rect.right - 50}px`;
      xpRef.current.style.top = `${rect.top -10}px`;
      xpRef.current?.classList?.add('add-xp');
     

     
     
      setTimeout(() => {
        xpRef?.current?.classList?.remove('add-xp');
      }, 3000); // Durée de l'animation
    }
  };

    const InputComponent = (props) => {
      const length = props.text?.split('')?.length 
      
      const currentLvlTrigger =  props.contextForInput.currentLvlTrigger
      const helpLetter = currentLvlTrigger == 1 ? 1.7 : currentLvlTrigger == 2 ? 2 : 4
      const word =  props.letters?.map(l => l.letter)?.join("")
      // const hardMode = props.hardMode
      const hardMode = false
      const disabled = props.contextForInput.disabled
      console.log('props input', props)
   
     
      const [correct, setCorrect] = useState(false)
      const answer = props.text?.toLowerCase()?.trim();


   

  
    

     if (isFliped) return null
    //  if (correct) return <span className='text-purple-600 text-blink fredoka'>{props.text}</span> 
     
      return  <div className={'content 	 font-mono tracking-widest mono-regular relative  inline-flex'}>
        <div id="bganswer" className={`px-1 mt-1 text-xl -mb-2 transition-all rounded-md -mt-1 border-2 text-purple-500 relative border-b-4  ${(correct || disabled) ? "border-transparent bg-purple-100" : "bg-white border-white"}  flex  `}>
    <div className='rounded-md -mb-1'>{"_".repeat(length)}</div>
  </div>

        <input id="answer" 
          autoFocus={true} 
          disabled={disabled}
          autoComplete="off" 
          autoCorrect="off"
          onChange={(e) => handleChange(e,answer)} 
          onBlur={(e) => {  
  
        console.log('e', e.target)
        
     
        const val = e.target.value?.toLowerCase().trim();
        const goodWithoutAccent = val !== answer && comparerSansAccents(val || "", answer || "");
        console.log('answer',answer)
        if (val == "" || val == null ) return
        if (!(val === answer || goodWithoutAccent)) return

        console.log('juste!!')
        toDoCards[0]?.original_card?.contexts?.length && speak({text: toDoCards[0]?.original_card?.term + "... . ..." + context, lang: ct.workspace.lang}, ct)

       
        handleAddXp(e)
        setCorrect(true)
        const timer = setTimeout(() => {

       
     
        
          if (toDoCards.length > 0 && toDoCards[0].original_card && ct.workspace) {
              if (!toDoCards[0].user_card) {
                  setInputState(3);
                 
              } else {
                  validDB(true, false, false); // Assuming validDB is a function you've defined elsewhere
                  setIsFliped(true);
              }
              }
              setCorrect(true);

              // Speak

          }, 900);
          

          // Cleanup function for the timeout
          return () => clearTimeout(timer);
      }}
      className={`px-[5px] mono-regular leading-[16px] text-xl overflow-hidden z-0 bottom-[-2px] tracking-widest border-none ${hardMode ? "text-[#b527db] focus-visible:border-pink-500 border-pink-500": " text-purple-600 focus-visible:border-indigo-500 border-indigo-500"} absolute placeholder:text-purple-500/60  !bg-transparent  left-0 right-0 w-full rounded-b-none  rounded-top-md  focus-visible:outline-none  border-b-2 `} 
      placeholder={showHint || hardMode ? 
        // toDoCards[0]?.original_card?.term[0] + "_".repeat(length -1) 
        toDoCards[0]?.original_card?.term.slice(0, Math.round((length/helpLetter))) + " ".repeat(length -1 -Math.round(length/helpLetter)) + toDoCards[0]?.original_card?.term.slice(-1) 

        : " ".repeat(length)} 
      defaultValue={word} 
      // style={{width: 14*length > 300 ? 300 : 14*length, maxWidth: "60%"}}
       /></div>;
    
    }

    let context = toDoCards && toDoCards[0]?.original_card?.context
    let context_trad = toDoCards && toDoCards[0]?.original_card?.context_trad
   

    const next_date = userCard?.next_date?.seconds ? new Date(userCard.next_date.seconds * 1000) : userCard?.next_date
    const elTriggosBefore = toDoCards[0]?.user_card?.triggers[trigger_index]
    const elTriggos = userCard?.triggers[trigger_index]
    // console.log('elTriggosBefore', elTriggosBefore)
    // console.log('elTriggos', elTriggos)
    let perfect_date = new Date()
    perfect_date.setMinutes(perfect_date?.getMinutes() + boosters[elTriggos?.lvl|| 1]?.time)  
    const nextDurationText = displayDuration(new Date(), next_date).replace('jours', "j")
    const nextDurationTextTrigger = displayDuration(new Date(), elTriggos?.next_date).replace('jours', "j")
    const nextDurationTextTriggerEasy = displayDuration(new Date(), perfect_date).replace('jours', "j")

    const currentLvlTrigger = toDoCards[0]?.user_card?.triggers?.[trigger_index]?.lvl +1

    const nextTriggerisNow = (nextDurationText == "maintenant" || nextDurationText == "0 s")
    const haveChoiceTooEasy = (currentLvlTrigger > 2 && error == 0 )
    const haveChoiceTooHard = (error > 0 && trigger_index == 1)
    const onlyHard = (showHint == 2) || (showHint == 1 && currentLvlTrigger > 3 )


    const _back = toDoCards && toDoCards[0] ? card?.model == "grammar" ? <div></div> : card?.model == "classic" ?  
    <div className="back">
    
    <div className="top">!!</div>
    
    <div className="center  flex-col justify-start ">
    
        {!toDoCards[0]?.original_card.def_img && <div className='h-32 '></div>}
        
    
        <div id="term-back" className="term">
          {renderText(getTitle(toDoCards[0]?.original_card.term))} 
          </div>
        <div id="def-back" className="def ">
          {toDoCards[0]?.original_card.def_img && <img className='rounded-xl mb-2' src={toDoCards[0]?.original_card.def_img} style={{maxHeight: '33vh'}}/> }
          {toDoCards[0]?.original_card.def && renderText(toDoCards[0]?.original_card.def)}
          {renderText(toDoCards[0]?.original_card.context)}
          </div>
  
    </div>
    <div className="bottom no-flip !bg-transparent">
      {toDoCards && toDoCards[0].user_card?.next_date ? <div className='flex justify-between w-full items-center'>
      <div id="try-again" className="flex text-white gap-2 rounded-full px-4 py-2 pr-5 items-center text-xl bg-purple no-flip" onClick={(e) => {e.stopPropagation(); validFlashCard(false)}}>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 ">
          <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
        </svg>
        A revoir
      </div>
        <div id="valid" className="flex text-white gap-2 rounded-full items-center pl-5 text-xl px-4 py-2 bg-green no-flip" onClick={async(e) => {e.stopPropagation(); 
          const updatedCard = await validDB(true); 
          validFlashCard(true, updatedCard)
          }}>
          Je sais
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round"d="M4.5 12.75l6 6 9-13.5" />
          </svg>
      </div>
      </div> : <div  onClick={async(e) => {
        e.stopPropagation(); 
        const updatedCard = await validDB(true); 
        validFlashCard(true, updatedCard)
        }} className='mx-auto cursor-pointer border-b-4 border-indigo-700 mt-10 flex items-center gap-3 px-8 py-3 rounded-full bg-indigo-500 text-white font-semibold text-xl'>Suivant 
      →</div>} 
      
      
    </div>
    </div> : <>
    <div className='absolute top-2 z-10 right-2 border-pink-300 p-2 bg-pink-100 border-2 rounded-xl text-pink-500' onClick={() => {
          ct.setShowInfo(pops(ct, "cardBugReport", {term: toDoCards[0]?.original_card?.term, card_id: toDoCards[0]?.original_card?.id}))
        }} ><svg className='h-4 w-4 ' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M19 15V11.9375C19 9.76288 17.2371 8 15.0625 8H8.9375C6.76288 8 5 9.76288 5 11.9375V15C5 18.866 8.13401 22 12 22C15.866 22 19 18.866 19 15Z" stroke="currentColor" strokeWidth="2"></path> <path d="M16.5 8.5V7.5C16.5 5.01472 14.4853 3 12 3C9.51472 3 7.5 5.01472 7.5 7.5V8.5" stroke="currentColor" strokeWidth="2"></path> <path d="M19 14H22" stroke="currentColor" strokeWidth="2" strokeLinecap="round"></path> <path d="M5 14H2" stroke="currentColor" strokeWidth="2" strokeLinecap="round"></path> <path d="M14.5 3.5L17 2" stroke="currentColor" strokeWidth="2" strokeLinecap="round"></path> <path d="M9.5 3.5L7 2" stroke="currentColor" strokeWidth="2" strokeLinecap="round"></path> <path d="M20.5 20.0002L18.5 19.2002" stroke="currentColor" strokeWidth="2" strokeLinecap="round"></path> <path d="M20.5 7.9998L18.5 8.7998" stroke="currentColor" strokeWidth="2" strokeLinecap="round"></path> <path d="M3.5 20.0002L5.5 19.2002" stroke="currentColor" strokeWidth="2" strokeLinecap="round"></path> <path d="M3.5 7.9998L5.5 8.7998" stroke="currentColor" strokeWidth="2" strokeLinecap="round"></path> <path d="M12 21.5V15" stroke="currentColor" strokeWidth="2" strokeLinecap="round"></path> </g></svg> 
        </div>
    <div id="back"  className={`center text-left ${showBack ? "": "!opacity-[0] "} overflow-y-auto rounded-2xl flex flex-col justify-start `}>
   

    <div>{accentWarning && <div className='bg-yellow-400/20 my-2  rounded-xl text-amber-500 items-center gap-2 px-2 flex '> <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
  <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z" />
</svg>
 Attention à l'accent !</div>}</div>
    
    
    <div id="def-back" className="def ">
      {/* {toDoCards && toDoCards[0]?.original_card?.img && <div className='rounded-sm bg-green-500  rotate-45 -mt-2 -mb-[16px] ml-[30px] object-cover h-[20px] w-[20px]' src={toDoCards[0]?.original_card?.img?.replace('&h=200', '&h=350')} /> } */}

      {/* {toDoCards[0]?.original_card?.img && <img className='rounded-xl relative mb-2 w-full object-cover' src={toDoCards[0]?.original_card?.img?.replace('&h=200', '&h=350')} style={{maxHeight: '30vh'}}/> } */}
      <div className={` rounded-xl transition relative `}>
              {(toDoCards[0]?.original_card.img_400 || toDoCards[0]?.original_card.img) ? <img className="max-h-[250px] rounded-xl h-[250px] object-cover rounded-xl w-full" src={toDoCards && (toDoCards[0]?.original_card.img_400 || toDoCards[0]?.original_card.img?.replace('&h=200', '&h=400').replace('&w=280', '&w=600'))} /> : <div className='h-[50px]'></div>}
          
                <div id="term-back" className="term flex text-center -ml-4 justify-center mt-2 items-start gap-2 mb-0  " style={{fontSize: toDoCards && toDoCards[0]?.original_card?.term?.split(' ').length > 3 ? 20 : 32}}>
                  <svg onClick={(e) => {e.stopPropagation(); speak({text:toDoCards && toDoCards[0]?.original_card?.term, lang: ct.workspace.lang, upload: true}, true)}} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="text-[#4B368C] mt-[6px] -ml-1 bg-blue-500/20 p-[1px] rounded-full w-6 h-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M19.114 5.636a9 9 0 010 12.728M16.463 8.288a5.25 5.25 0 010 7.424M6.75 8.25l4.72-4.72a.75.75 0 011.28.53v15.88a.75.75 0 01-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.01 9.01 0 012.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75z" />
                  </svg>
                  <div className='fredoka -ml-1  text-[#8056FF] font-bold text-2xl text-center'>{getTitle(capitalizeFirstLetter(toDoCards && toDoCards[0]?.original_card.term))}</div>
           
                </div>
              
                {/* <DisplayMore more={toDoCards?.[0]?.original_card?.more} little={true}/> */}
                {/* <div className='text-sm -mt-2 text-center text-black/30'>{toDoCards && toDoCards[0]?.original_card?.trad}</div> */}

              <div  id='speak' onClick={(e) => {e.stopPropagation(); speak({text: toDoCards?.length && toDoCards[0]?.original_card?.term, lang: ct.workspace?.lang, upload: true})}}  className='absolute rounded-xl novisible show-after-2s bg-white/10 bottom-0 top-0 right-0 left-0 flex justify-end invisible '></div>
            </div>
  
              { context && <div onClick={() => {console.log("c", context)}} className='px-3 pl-3 py-[6px] bg-[#e8e0ff] mt-2 rounded-xl border border-[#eddfff]'>
      
        <div className='flex  items-start gap-2'>
        <svg onClick={(e) => {e.stopPropagation() ;speak({text: context, lang: ct.workspace.lang, upload: true})}} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="text-[#7459c3]  mt-1  rounded-full w-5 h-5">
              <path strokeLinecap="round" strokeLinejoin="round" d="M19.114 5.636a9 9 0 010 12.728M16.463 8.288a5.25 5.25 0 010 7.424M6.75 8.25l4.72-4.72a.75.75 0 011.28.53v15.88a.75.75 0 01-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.01 9.01 0 012.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75z" />
          </svg>
          <div className='text-[#7459c3] font-semibold text-[16px] ' dangerouslySetInnerHTML={{ __html: replaceBracketsWithUnderLine(context, "text-purple-500") }}></div>


        {/* <div className='text-slate-700 text-xl' dangerouslySetInnerHTML={{ __html:  }}></div> */}
      
          </div>
          <div className='text-black/40 text-xs italic ml-[24px]' dangerouslySetInnerHTML={{ __html: ``+replaceBracketsWithUnderLine(context_trad, "text-slate-500")+`` }}></div>
          </div>}

        
       



          {_hint}
          {toDoCards[0]?.original_card?.more ? <div>
          {!showMore ? <div className='flex justify-center '><div onClick={async() => {
              if (showInfoLoading) return
              if (toDoCards[0]?.original_card?.more) {
                setShowMore(toDoCards[0]?.original_card?.more)
              } else {
                // const requestText = requestInfoMore(ct.workspace?.name, toDoCards[0]?.original_card?.term)
                // console.log('requestText', requestText)
                // const request =  [{ "role": "user", "content": requestText }]
                // setShowInfoLoading(true)
  
                // const requestData = await gpt(request, true, ct, {temp: 0.3})
  
                // const data_content = requestData.choices[0]?.message?.content;
                // console.log('data_content', data_content);
                // const jsonData = JSON.parse(data_content)
                // console.log('requestData', requestData)
                // console.log('jsonData', jsonData)
                // console.log('ID', toDoCards[0]?.original_card?.id)
                // ct.fire.updateDb("cards", {id: toDoCards[0]?.original_card?.id, more: jsonData})
                // setShowMore(jsonData)
              }
            

          
              // Ne pas permettre double clique 
            
            
          }} className={`${showInfoLoading ? "opacity-50" : ""} px-3 py-1 rounded-xl border-2 border-black/10 bg-amber-200/10 text-sm flex items-center text-black/50 mt-3`}>{showInfoLoading ? "Chargement" : "En savoir plus"} <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`ml-2 w-4 h-4 ${showInfoLoading ? "animate-spin" : ""}`}>
          <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.042A8.967 8.967 0 0 0 6 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 0 1 6 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 0 1 6-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0 0 18 18a8.967 8.967 0 0 0-6 2.292m0-14.25v14.25" />
        </svg>
         </div></div> : <div className='flex w-full justify-center'>
            {/* <div onClick={() => setShowMore(false)} className='bg-black/10 opacity-50 rounded-xl text-sm px-2 mt-2'>Voir moins - </div> */}
            </div>}

          <div className='pt-2 text-center text-black/10'></div>
          
          <DisplayMore more={toDoCards[0]?.original_card?.more} />
         

          {ct.user.admin && <div className='flex'><div className='mt-4 mb-4 px-4 border-2 text-slate-500 text-sm rounded-xl ' onClick={async() => {
             const requestText = requestInfoMore(ct.workspace?.name, toDoCards[0]?.original_card?.term)
                console.log('requestText', requestText)
                const request =  [{ "role": "user", "content": requestText }]
                setShowInfoLoading(true)
  
                const requestData = await gpt(request, true, ct, {temp: 0.3})
  
                const data_content = requestData.choices[0]?.message?.content;
                console.log('data_content', data_content);
                const jsonData = JSON.parse(data_content)
                console.log('requestData', requestData)
                console.log('jsonData', jsonData)
                console.log('ID', toDoCards[0]?.original_card?.id)
                ct.fire.updateDb("cards", {id: toDoCards[0]?.original_card?.id, more: jsonData})
                setShowMore(jsonData)
          }}>Modifier la définition</div></div>}

           {/* {ct.user.admin && <div className='flex'><div className='mt-4 mb-0 px-4 border-2 text-slate-500 text-sm rounded-xl ' onClick={async() => {
             const hint = await findHint({term: toDoCards[0]?.original_card?.term, lang: ct.workspace.name})
             console.log('hint', hint)
             setNewCard(e => ({...e, hint: hint}))
             setToDoCards(card => card?.original_card?.id == toDoCards?.[0]?.original_card?.id ? {...card, hint: hint} : card)
             
               
          }}>Générer une astuce</div></div>} */}
          {ct.user.admin &&  <div className='flex'><div className='mt-4 mb-0 px-4 border-2 text-slate-500 text-sm rounded-xl ' onClick={() => {ct.setMagnifier({...toDoCards[0]?.original_card, word: toDoCards[0]?.original_card?.term, seeCard: true});}}>voir la carte</div></div>}
          {newCard?.hint && <div className='bg-pink-500 mt-2 px-2 py-1 pb)2 text-white rounded-xl text-sm'>
            <textarea className='bg-pink-500 w-full text-white' id="newHint">{newCard?.hint}</textarea>
            <div className='px-2 inline-flex rounded-xl bg-white/10 mt-1 ' onClick={() => {
                const newHint = document.querySelector('#newHint')?.value
                console.log('newHint', newHint)
                ct.fire.updateDb("cards", {id: toDoCards[0]?.original_card?.id, hint: newHint})
            }}>Valider</div>
            </div>}
          
          <div className='h-20'></div>



          {showMore?.definitions?.map((def) => {
          const freq = parseInt(def.freq?.replace('%', ''), 10)
          if (freq && freq < 11) return
          return <div className='bg-white/10 mt-4 rouned-xl'>
            <div className='text-sm opacity-50' >{def.trad_fr} [{def.categorie}] - {freq}%</div>
            {/* <TargetSentence text={def.def} trad={def.def_fr} _showTrad={false} classText={""}  /> */}
            <div className='mt-2'>{def.ex?.map(ex => <div className='mt-1 flex gap-2'>
              <div className='min-h-[5px] h-[5px] mt-2 min-w-[5px] rounded-full bg-purple-500'></div> <TargetSentence text={"“"+ex.text+"“"} trad={ex.trad_fr} />
            </div>)}</div>
            </div>
          })}

          
          <div className='mb-8 text-center text-black/10'></div>

          {/* <div className='mb-16' onClick={async() => {
            const requestText = requestInfoMore(ct.workspace?.name, toDoCards[0]?.original_card?.term)
            console.log('requestText', requestText)
            const request =  [{ "role": "user", "content": requestText }]
            setShowInfoLoading(true)

            const requestData = await gpt(request, true, ct, {temp: 0.3})

            const data_content = requestData.choices[0]?.message?.content;
            console.log('data_content', data_content);
            const jsonData = JSON.parse(data_content)
            console.log('requestData', requestData)
            console.log('jsonData', jsonData)
            console.log('ID', toDoCards[0]?.original_card?.id)
            ct.fire.updateDb("cards", {id: toDoCards[0]?.original_card?.id, more: jsonData})
            setShowMore(jsonData)
          }}>recharger</div> */}

          </div> : null}
          {/* {toDoCards && !toDoCards[0].user_card && <div className='flex mt-4 justify-center text-slate-400'><div onClick={async (e) => {
            e.stopPropagation(); 
            const updatedCard = await validDB(true, true); 
            validFlashCard(true, updatedCard); 
            }} className='bg-[#00000008] text-sm rounded-xl px-3 py-1'>Ne pas apprendre cette carte</div></div>} */}

        

      </div>
      {/* <div onClick={(e) => {e.stopPropagation();ct.fire.updateCard({...toDoCards[0]?.original_card, bug: (toDoCards[0]?.original_card?.bug || 0) + 1})}} className='mx-auto cursor-pointer inline-block rounded-xl text-slate-900/50 px-3 py-1 mt-8 bg-slate-400/10'>Signaler un bug {toDoCards[0]?.original_card.bug}</div> */}

     
    </div> 
    {userCard?.next_date < new Date ? <div></div>: <div className='absolute fredoka bottom-0 left-0 right-0  '>
      <div className='bg-[#EBE3E0]  border-t border border-[#ffe4b7]/10 rounded-b-[12px]  pb-[70px] rounded-t-[0px] p-6   pt-2 flex gap-2'>
        <div className=' gap-2 text-left justify-between items-center  grow '>
        <div className='text-[#876DD7] opacity-[0.9] w-full grow font-semibold font-[400] text-sm text-center'>{trigger?.name} 
        {/* <span className='bg-white/80 text-xs px-2 text-purple-500 font-base rounded-full'>niv {toDoCards[0]?.user_card.triggers[trigger_index]?.lvl}</span> */}
        </div>
          <div className='flex h-[0px] gap-2 items-center max-w-[55%]'> 
            {!haveChoiceTooEasy ? <div className='h-[48px] w-[48px]  rounded-md bg-white/10 relative '>
              {/* <div className=' absolute h-[38px] w-[38px] bg-[#472f7e] rounded-full bottom-[-8px] left-[3px] ' style={{transform: "rotate3d(17, 42.3, 30, 206deg)"}}></div>
              <div className=' absolute h-[34px] w-[34px] bg-[#7e22ce] rounded-full bottom-[-3px] left-[5px] ' style={{transform: "rotate3d(17, 42.3, 30, 206deg)"}}></div> */}
              {/* <img src={`/images/seeds/simple-${userCard?.lvl || 0}.png`} className='absolute bottom-[18px] h-[50px] left-0 scale-[2] ' /> */}
            {/* <div className=' absolute h-[4px] w-[28px] bg-[#94BC62] rounded-xl bottom-[15px] left-[8px] '></div> */}
            {/* <div className='absolute bottom-[-2px] w-[36px] left-[4px] text-[10px] text-indigo-500 text-center'>niv {userCard?.lvl}</div> */}

            </div>: ""}
            <div className='w-full grow '>
           

              {/* <div className='text-[#876DD7] font-semibold text-sm '>{userCard?.lvl ? textLvl[userCard?.lvl || 1] : "Nouveau souvenir"}</div> */}
              {/* <div className='text-xs text-[#9080B0]'><u>{userCard?.next_trigger ? "Expression" : "Compréhension"}</u> à rafraichir dans</div> */}
            </div>
        </div>
        {/* <div className='text-[#997af7] font-bold py-1 rounded-full grow min-w-[70px] text-right  '>
          {displayDuration(new Date(), userCard?.next_date) == "maintenant" ? "< 10s" : displayDuration(new Date(), userCard?.next_date)}
        </div> */}
      </div>
      </div>
      </div>}
    </> : <div></div>











    const showTrigger = useMemo(() => (card, user_card, triggers) => {

      if (!card) return 


      const defs1 = card?.more?.definitions?.map(def => def.trad_fr).join(', ');

// Filtrage des doublons après le join
      const defs = Array.from(new Set(defs1?.split(', '))).slice(0,2)?.join(', ');
    
     
        context = card.context
        context_trad = card.context_trad
        
      
     

     

    //  return _back
      if (card.model == "grammar")
      {
       
       return <div className=' flex grow h-[600px] flex-col h-full'>
        <div className=' grow flex flex-col justify-end'>
          <div className='text-xl text-slate-700'> {!error ? card.term?.split('|')[0].replace(/\[([^\]]+)\]/g, (_, match) => '_'.repeat(5)) : card.term.split('|')[0]}</div>
          <div className='group'>
            {/* <div className='group-hover:hidden block text-sm mt-2 italic text-slate-500'>Voir la traduction</div> */}
            <div className='group-hover:block text-sm mt-2 italic text-slate-500'> {card.term?.split('|')[2].replace("[", "").replace("]", "")}</div>
          </div>
        </div>

        <div className='flex gap-2 flex-wrap mt-8'>
        {!error && card.term?.split('|')[1].split(',').map(word => 
          <div onClick={async() => {
            const correct = (card.term?.match(/\[([^\]]+)\]/) || [])[1];
         
            if (correct.trim().toLowerCase() == word.trim().toLowerCase()){
              if (error) return
              const updatedCard = await validDB(true); 
              validFlashCard(true, updatedCard); 
            } else {
              const updatedCard = await validDB(false); 
             // validFlashCard(false, updatedCard); 
              setErrors(1)

            }
          }} className='border-2 bg-white text-slate-700 border-b-4 grow fredoka min-w-[130px] p-2 rounded-xl'>{word}</div>
        )}
        </div>
        <div className='mt-8'>
       
        {error ? <div>
          <div className='text-amber-700 text-left mb-4'>{card.term?.split('|')[3]}</div>        
          </div> : <div></div>}
          <div className='flex flex-col justify-center items-center gap-2'>
          <div onClick={() => setShowDeck(true)} className='absolute bottom-4 text-amber-600 bg-amber-500/20 border-2 border-amber-500/20 px-6 py-2 rounded-xl flex items-center gap-4  mx-auto'>Voir la leçon <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.042A8.967 8.967 0 006 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 016 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 016-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0018 18a8.967 8.967 0 00-6 2.292m0-14.25v14.25" />
          </svg>
          </div>

            {/* suivant des cartes classique errors */}
            {error != 0 && <div  onClick={async(e) => {validFlashCard(false, updatedCard)}} className='px-[16px] px-8 py-3 border-2 border-b-4 border-indigo-600 w-[50%] mx-auto mx-auto text-white bg-indigo-500 rounded-xl '>Suivant →</div>}

          </div>
          </div>
    

 
        </div>
      }
        if (!user_card) {
     
          let consign = "Choisis le bon terme"
          if (inputState == 1) consign = "Place les mots dans le bon ordre"
          if (inputState == 2) consign = "Ecris le mot manquant"              
          const result = error > 0 ? [...randomCardDeck.filter(item => item.valid), ...randomCardDeck.filter(item => !item.valid).slice(0, 1)] : randomCardDeck;
         
          if (card.model == "classic") {

            return <div>
           
              <SuperEditor key={"title"+card.id} className={"text-xl text-blue-500 font-medium"} content={getTitle(toDoCards[0]?.original_card?.term)} />
              {<div onClick={async() => { const updatedCard = await validDB(true); 
                    validFlashCard(true, updatedCard)
                    }}   className='text-xl text-green-500'>{getCons(toDoCards[0]?.original_card.term)}</div> ||  
                    <div className="sub" onClick={async () => { 
                      console.log('shortcut')
                      const updatedCard = await validDB(true); 
                      console.log('shortcut updatedCard', updatedCard)
                      validFlashCard(true, updatedCard);
                      console.log('shortcut validFlashCard')
                      }}  >{trigger?.consign ? trigger?.consign[0] : "Trouve la réponse et retourne la carte"}</div>}

              <div className='bg-green-500 text-white text-lg gap-3 rounded-full px-3 py-1 inline-block mx-auto mt-4 text-center' onClick={() => {setIsFliped(true); toDoCards[0]?.original_card?.contexts?.length && speak({text: toDoCards[0]?.original_card?.context, lang: ct.workspace.lang})}}>Voir la réponse →</div>
            </div>
          }
          // A modifier
         
          return <>
        
           {inputState != 3 && <div  onClick={async()=> {   
                    console.log('shortcut')
                    const updatedCard = await validDB(true); 
                    console.log('shortcut updatedCard', updatedCard)
                    
                    validFlashCard(true, updatedCard)
                    console.log('shortcut validFlashCard')
                    }}  
                    className='text-amber-500 text-sm mt-4'>{consign}</div>}
       
           {inputState != 3 && (inputState != 0 ||  deck?.presentation_mode != "Dialogue")&& <>
           
            
            <div 
           
              className={`relative rounded-xl group group-hover  cursor-pointer transition ${ inputState == 1 ? "scale-[65%] -mt-6" : "scale-[75%] "} `}>
              <img className="max-h-[250px] rounded-xl h-[250px] object-cover rounded-xl w-full" src={card.img_400 || card.img?.replace('&h=200', '&h=400').replace('&w=280', '&w=600')} />
              <div className='bg-gradient-to-b from-[#11182700]  to-[#000000a3]  pt-16 py-2 gradient-over-img rounded-b-xl text-white absolute flex flex-col items-center bottom-0 left-0 right-0'>
              
              {(inputState == 1 || inputState == 2) && <div className='hidden-term group-hover:block  text-green-300 text-3xl fredoka font-semibold'>{toDoCards[0]?.original_card?.term}</div>}
              {inputState == 2 && <div 
                               
                              className='group-hover:hidden sound reveal-term text-slate-400 px-3 py-1 text-md text-center mt-[10px] mb-[12px] scale-[150%] inline-block mx-auto rounded-full gap-2 flex bg-blue-500 text-white items-center'>Voir le terme <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                              <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                              <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                            </svg> 
                            </div>}
              {/* {inputState == 1 || (inputState == 2 && showTerm) && <div className='hidden-term text-green-300 text-3xl fredoka font-semibold'>{toDoCards[0]?.original_card?.term}</div> } */}
                <div className='italic text-lg '>{toDoCards[0]?.original_card?.trad}</div>
                </div>
              
              <div  id='speak' onClick={(e) => {e.stopPropagation(); speak({text: toDoCards[0]?.original_card?.term, lang: ct.workspace.lang, upload: true})}}  className='absolute rounded-xl   bg-white/10 bottom-0 top-0 right-0 left-0 flex justify-end  '>
              {inputState == 0 &&  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className=" -mt-4 -mr-4 bg-blue-500  p-4 text-blue-100 rounded-full w-20 h-20">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M19.114 5.636a9 9 0 010 12.728M16.463 8.288a5.25 5.25 0 010 7.424M6.75 8.25l4.72-4.72a.75.75 0 011.28.53v15.88a.75.75 0 01-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.01 9.01 0 012.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75z" />
                </svg> }
              </div>
            </div>
            </>}
            {deck?.presentation_mode == "Dialogue" && inputState == 0 && <div className='text-2xl text-amber-500 my-4'>
              {toDoCards[0]?.original_card?.term}
              </div>} 


          
            {deck?.presentation_mode == "Dialogue" && inputState == 0 && <div>
            
              <div className='h-[250px] p-2 rounded-xl grid grid-cols-2  gap-2 mx-auto'>
              {result?.map((card, index) => <div className={`relative overflow-hidden cursor-pointer border-2 rounded-xl border-b-[5px]  ${showInputValidation == index ? card.valid ? "border-green-400  border-b-[5px]" : "border-red-400 border-b-[5px]": ""}`}>
                <img src={card.img_400 || card.img?.replace('&h=200', '&h=350')} 
                        onClick={(e) => {
                          e.stopPropagation(); 
                          setShowInputValidation(index); 
                          if (card.valid) {
                            handleAddXp(e)
                            setTimeout(() => {
                           
                           validSound.play() 
                           setInputState(1)
                          }, [500]);
                          } else  {
                          
                            setErrors(error+1)
                          }
                          
                          }}
                        className={`w-full h-full object-cover  `} />
                        <div className='absolute bottom-0 rounded-b-xl bg-black/40 text-slate-200 left-0 right-0 pb-1 novisible show-after-2s '>{card.trad}</div>
                        </div>)}
              </div>
              </div>} 
            {inputState == 0 &&  deck?.presentation_mode != "Dialogue" && <div>
              <div className='text-black/50 text-xs mb-4 -mt-5 italic'>"{card.contexts && card.contexts[0].trad}"</div>
              <div className='flex gap-2 flex-wrap'>           
           
            {randomCardDeck?.map((card, index) => <div key={'rd_'+index} onClick={(e) => {
                            e.stopPropagation(); 
                            setShowInputValidation(index); 
                           
                            if (card.valid) {
                              validSound.play() 
                              setTimeout(() => {
                            
                             
                              setInputState(1)
                            }, [500]);
                            } else  {
                              badSound.play()
                              setErrors(error+1)
                            }
                            
                            }}
                        className={`py-2 px-2 flex items-center justify-center w-[48%] border-4 b bg-white object-cover rounded-xl ${showInputValidation == index ? (card.valid ? "border-green-400 text-blink  scale-[1.1]" : "border-red-400 text-slate-500") :  showInputValidation != null ? "scale-[0.9] text-slate-500" : "text-slate-500 scale-[1]"}`} >
                        <div className=' '>{capitalizeFirstLetter(card.term)} </div>
                        </div>)}
                        <div className='flex mt-4 z-10 bg-red-500 absolute z-10 bottom-4 right-0 left-0 justify-center grow  text-slate-500 opacity-[0.8]'>
                         
          </div>
                </div></div>}
                       
                          {inputState == 1 && 
                          <div className='-mt-6'>
                          
                          <div className='flex justify-center flex-wrap'>
                         
                            <div id="sound_context" onClick={async(e) => {
                              e.stopPropagation() ; 
                              setSpeakingContext(true); 
                             
                              const isFinised = await speak({text: toDoCards[0]?.original_card?.context, lang: ct.workspace.lang, upload: true})
                              if (isFinised) setSpeakingContext(false)
                              }} className={`${speakingContext ? "opacity-50 noClick" : ""} border-2 border-black/50 border-b-4 flex gap-1 bg-blue-500 p-4 my-2 -mt-6 z-10 text-white lg:hover:scale-105 transition rounded-2xl`}>
                              <svg   xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M19.114 5.636a9 9 0 010 12.728M16.463 8.288a5.25 5.25 0 010 7.424M6.75 8.25l4.72-4.72a.75.75 0 011.28.53v15.88a.75.75 0 01-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.01 9.01 0 012.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75z" />
                              </svg>
                              <div className=''></div>
                              <img src={'/images/icons/sound_long.svg'} />
                            </div>
                            <div className='italic text-slate-500 w-full text-sm mb-2'>“{card?.contexts[0]?.trad}“</div>
                            {!selectedWords?.length && <div className='flex gap-2 justify-center flex-wrap w-full'>{randomWordsContext?.map((word, i) => <div className={`border border-dashed py-[2px] rounded-md text-[10px] px-2 py-1 text-slate-200 bg-white/50 border-slate-400`}>{'_'.repeat(word.length)}</div>)}</div>}

                            <div className='flex gap-2  justify-center  flex-wrap w-full'>{selectedWords?.map((word, i) => <div onClick={() => {
                              console.log('coucoucouc')
                              setSelectedWords(selectedWords?.filter(w => w != word))
                              }} className={`border border-b-4 rounded-xl px-2 py-1 bg-white border-slate-500 ${cleanText(word.word) == cleanText(toDoCards[0]?.original_card?.term) && "text-amber-600"}`}>{word.word}</div>)}</div>

                            <hr  className='my-4 w-full'/>
                          {/* <div className='flex gap-2 flex-wrap'>{randomWords.map((word, i) => <div onClick={() => setSelectedWords([...selectedWords, {word, i}])} className={`border b lg:hover:border-blue-500 lg:hover:text-blue-500 transition border-b-4 rounded-xl px-2 bg-white ${selectedWords.map(w => w.i).includes(i) ? "border-slate-100 text-slate-100 " : "border-slate-400"}`}>{word}</div>)}</div> */}
                    
                          </div>

                       
          
                         </div>}

                         {inputState == 2 && 
                          <div className='-mt-6'>
                          
                          <div className='flex justify-center flex-wrap'>
                            <div className='flex w-full justify-center mb-4 flex-wrap'>
                             
                            </div>
                            <div>
                            {card.term.split(' ').length > 3 ?  
                    <div className='flex gap-2 flex-wrap mb-2 mt-2 '>{selectedWords.map((word, i) => <div className='border border-b-4 rounded-xl px-2 bg-white border-slate-500'>{word.word}</div>)}
                    </div> : 
                    <div className={`text-purple-500 ${textSize(context)}`}>{replaceBracketsWithInput(addBracket(context, card.term), InputComponent, selectedLetter, toDoCards?.[0]?.user_card?.hard_exp, {disabled: showHint == 2, currentLvlTrigger})}</div>
                    }

                    {/*  */}
                    </div>
                           
                          {/* <div className='flex gap-2 flex-wrap'>{randomWords.map((word, i) => <div onClick={() => setSelectedWords([...selectedWords, {word, i}])} className={`border b lg:hover:border-blue-500 lg:hover:text-blue-500 transition border-b-4 rounded-xl px-2 bg-white ${selectedWords.map(w => w.i).includes(i) ? "border-slate-100 text-slate-100 " : "border-slate-400"}`}>{word}</div>)}</div> */}
                    
                          </div>

                       <div className='absolute bottom-4 flex left-0 right-0 justify-center'>{skipBtn}</div>
          
                         </div>
                         }
                         


              
            {inputState == 3 && _back}
            </> 
        }
        if (card.model == "lang-en") {

            if (trigger?.name == "Expression") {
                //learnMode
                // stopSound()
             

                // let context = card.contexts && card.contexts[0] 
                const xLetter = Math.ceil((card.term?.length ) / 3)
                const brackedText = addBracket(context, card.term)
                // const brackedText = toDoCards?.[0]?.user_card?.hard_exp && card.term?.length > 3 ? reduceInput(addBracket(context, card.term), xLetter, 0) : addBracket(context, card.term)
               

                const contextToComplete = <div className={`text-purple-500 ${textSize(context)}`}>{context && replaceBracketsWithInput(
                  //context?.replace(new RegExp(card.term, 'gi'), `[*$&*]`)
                  brackedText
                  , InputComponent, selectedLetter, toDoCards?.[0]?.user_card?.hard_exp, {disabled: showHint == 2, currentLvlTrigger})}</div>



               
                let currentTrigger = contextToComplete
                
                const splitedSentence = card?.contexts?.length && splitSentence(context.trim(), card?.term?.trim())


                if (learnMode == "context") {
                    if (inputState ==99) currentTrigger = <div>
                      <div className='flex gap-1 mt-2 flex-wrap justify-center '>
                      <div className='flex gap-1 flex-wrap justify-center'>{splitedSentence?.map((word, i) => {
                        const isTerm = cleanText(word) == cleanText(toDoCards[0]?.original_card?.term)
                        const isVisible = indexContextReveal < (i+1)
                      return <div  className={` transition  rounded-md text-[14px] ${isTerm ? "border-slate-400 text-slate-500" :  isVisible ? "border-purple-500  text-purple-500  "  : "border-slate-400 text-purple-500 "}`}>
                        {hideLetters(word)}
                      </div>})}</div></div>
                     <div className='text-xs text-black/50 mt-2 '>{context_trad}</div>
        
                      
                      </div>
                    if (inputState ==0) currentTrigger =  <div>
                                
                    <div className='flex gap-1 mt-2 flex-wrap justify-center '>
                    <div className='flex gap-1 flex-wrap justify-center'>{splitedSentence?.map((word, i) => {
                      const isTerm = cleanText(word) == cleanText(toDoCards[0]?.original_card?.term)
                      const isVisible = indexContextReveal < (i+1)
                    return <div 
                    onClick={() => { 
                      if (isTerm) {
                        setTimeout(() => {setInputState(2)}, 300)
                      }
                      if (isVisible) {
                        setIndexContextReveal(i+1)
                        !isTerm && speak({text: word, lang: ct.workspace.lang, upload: true}, ct)
                        if (i >= (splitedSentence.length-1) ) {
                          setTimeout(() => {setInputState(2)}, 300)
                        }
                      }
                     
                    
                    }} className={`border border-b-2 transition-all rounded-md ${splitedSentence?.length > 11 ? "text-md"  : splitedSentence?.length > 8 ? "text-lg" : splitedSentence?.length > 5 ? "text-xl" : "text-2xl"}  ${indexContextReveal} == i ${i} ${i == indexContextReveal && !isTerm? "iamhere" : ""}  ${isTerm ? "border-slate-400  text-slate-500 border-none px-[0px]" :  isVisible ? "border-purple-400  bg-gradient-to-r from-purple-300/80 to-purple-300 text-purple-500/50  font-bold  "  : "border-slate-400 text-purple-500 border-none px-[0px]"}`}>
                      {isTerm  ? hideLetters(word, "_") : isVisible ? <span className=' block flex gap-1 relative !text-transparent  '>{word}<span className='absolute left-0 -top-1 right-0 flex gap-1 px-1 scale-x-[0.9] scale-y-[0.5] justify-center'>{ word.split(' ').map(e => <div className='bg-purple-400 blur-[0px] !text-transparent mt-2  rounded-md '>{e}</div>)}</span></span> : word}
                    </div>})}</div></div>
                   <div className='text-xs text-black/50 mt-2 '>{context_trad}</div>
      
                    
                    </div>
                    if (inputState ==2) currentTrigger = <div className={`${splitedSentence?.length > 11 ? "text-md"  : splitedSentence?.length > 8 ? "text-lg" : splitedSentence?.length > 5 ? "text-xl" : "text-2xl"} text-purple-500`}>{contextToComplete}</div>

                }

                const img = (card.img_400 || card.img)

                return <div className='relative' onClick={(e) => e.stopPropagation()}>
                
                    <div className='relative mb-4 w-[80%] mx-auto'>
                        {img ? <img className="max-h-[220px] h-[220px] object-cover rounded-xl w-full" src={img.replace('&h=200', '&h=400').replace('&w=280', '&w=600')} /> : <div className='h-[100px]'></div>} 
                       
                        {(card.trad || defs) && <div className={`${img ? "text-slate-200 italic text-xs" : " text-white bg-indigo-500 p-6 h-[120px] flex justify-center items-center rounded-xl border-2 border-indigo-500"}  my-2  absolute -bottom-2 py-2 left-0 right-0 rounded-b-xl`} style={{backgroundColor: img ? 'rgba(0, 0, 0, 0.4)' : ''}}  >{card.trad || defs}</div>}
                       {/* {card.hint && <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-10 h-10 absolute text-white bottom-2 right-2 bg-pink-400 p-1 rounded-full">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M12 18v-5.25m0 0a6.01 6.01 0 001.5-.189m-1.5.189a6.01 6.01 0 01-1.5-.189m3.75 7.478a12.06 12.06 0 01-4.5 0m3.75 2.383a14.406 14.406 0 01-3 0M14.25 18v-.192c0-.983.658-1.823 1.508-2.316a7.5 7.5 0 10-7.517 0c.85.493 1.509 1.333 1.509 2.316V18" />
                        </svg>} */}
                    </div>
                  
                    {card.term.split(' ').length > 3 ?  
                    <div className='flex gap-2 flex-wrap'>{selectedWords.map((word, i) => <div className='border border-b-4 rounded-xl px-2 bg-white border-slate-500'>{word.word}</div>)}
                    </div> : currentTrigger
                   
                    }

                 
                   
                    {/* <div className='text-[#0A4D68]' dangerouslySetInnerHTML={{ __html: replaceBracketsWithInput(card.contexts[0].text.replace(new RegExp(card.term, 'gi'), `[*$&*]`), <div>coucou</div>) }}></div> */}
                </div>
            } else {

                if (trigger.id == "co") return  <div  className='text-center flex flex-col items-center justify-center'>
                <div onClick={() => lauchSpeak({text: card.term, lang: ct.workspace.lang}, "term")}  className={`${speakingTerm ? "!opacity-50" : ""} bg-blue-500 p-3 mb-4 rounded-full b`}>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="text-white w-10 h-10">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.114 5.636a9 9 0 010 12.728M16.463 8.288a5.25 5.25 0 010 7.424M6.75 8.25l4.72-4.72a.75.75 0 011.28.53v15.88a.75.75 0 01-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.01 9.01 0 012.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75z" />
                 </svg>
                 
                </div>
                

                <div onClick={(e) => {
                      e.stopPropagation() ; 
               
                      lauchSpeak({text: context, lang: ct.workspace.lang, upload: true})
                      
                 
                  }}  className={`${speakingContext ? "!opacity-[0.5]" : "opacity-[1]"} bg-white border-2 flex items-center gap-2 border-blue-500 text-blue-500 px-4 py-2 rounded-full b `}>
               
                 <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                   <path strokeLinecap="round" strokeLinejoin="round" d="M19.114 5.636a9 9 0 010 12.728M16.463 8.288a5.25 5.25 0 010 7.424M6.75 8.25l4.72-4.72a.75.75 0 011.28.53v15.88a.75.75 0 01-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.01 9.01 0 012.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75z" />
                </svg>
                <div className={`bg-blue-300 h-[6px] ${speakingContext ? "animate-pulse" : ""} rounded-full w-[100px]`}></div>
                

               </div>
               <div className='text-sm border fixed z-20 -bottom-[28px] text-white opacity-[0.9] mt-6 bg-white/10 rounded-xl px-2' onClick={() => {
                    ct.setCantListen(true)
                  setTimeout(() => {
                    ct.setCantListen(false)
                  }, [1000*60*10])
                
                  }}>Je ne peux pas écouter </div>
                {/* <div className='text-green-700 text-xl' dangerouslySetInnerHTML={{ __html: replaceBracketsWithUnderLine(card.context, "guess") }}></div> */}
              </div> 

                  let contextHTML = <div className={`text-green-600  ${textSizeXl(context)}`} dangerouslySetInnerHTML={{ __html: replaceBracketsWithUnderLine(addBracket(context, card.term))}}></div>

                  if (learnMode == "context") {
                    contextHTML = <div className='group'>
                      {/* <div className='group-hover:hidden px-3 py-1 text-green-500 rounded-xl inline-block mx-auto  bg-green-300/10 '>Voir le contexte</div> */}
                      {/* <div className='text-green-700 group-hover:block hidden italic' dangerouslySetInnerHTML={{ __html: `"`+replaceBracketsWithUnderLine(context, "guess")+`"` }}></div> */}
                      <div className={`text-green-600 ${textSize(context)}`} dangerouslySetInnerHTML={{ __html: replaceBracketsWithUnderLine(addBracket(context, card.term))}}></div>

                    </div>
                  }
                 
                
                return <div>
                    {/* {learnMode == "context" ? "" : <div className={`text-green-600 ${card.term?.length < 20 ? 'text-4xl' : 'text-xl'} `}>{card.term}</div>} */}
                    <div onClick={() => {console.log('c', card)}} className='bg-green-400/50 my-2 h-[100px] w-[120px] fredoka rounded-xl text-[60px] mx-auto text-green-500'>?</div>
                  
                    {contextHTML}
                </div>
            }
           
        }
        
        else {
          return <div>
            <SuperEditor key={"title"+card.id} className={"text-xl text-blue-500 font-medium"} content={getTitle(toDoCards[0]?.original_card?.term)} />
            {<div className='text-xl text-green-500' onClick={async() => { const updatedCard = await validDB(true); 
                    validFlashCard(true, updatedCard)
                    }} >{getCons(toDoCards[0]?.original_card.term)}</div> || 
                     <div className="sub" onClick={async() => { const updatedCard = await validDB(true); 
                      validFlashCard(true, updatedCard)
                      }}>{trigger?.consign ? trigger?.consign[0] : "Trouve la réponse et retourne la carte"}</div>}
          </div>
        }

    }, [toDoCards[0]?.card, toDoCards[0]?.user_card, selectedLetter, selectedWords, inputState, showHint,
    showBack, showInputValidation, setShowInputValidation
  ])

   

   
    const countUnique = arr => new Set(arr).size;
    const uniqueCardListId = countUnique(showCards?.filter(c => !c.user_card?.next_date).map(c => c.id)|| []) || 0
    const uniqueCardListIdDone = masteredCards?.filter(c => !c.user_card?.next_date).map(c => c.id)
    let done = masteredCards?.length + newPoint*2
    let toDo = showCards?.length + uniqueCardListId*2
    if (deck?.presentation_mode == "Grammar") {
      done = masteredCards?.length 
      toDo = showCards?.length
    }

    // console.log('masteredCards', masteredCards)
    let progressCent = ((done)/(toDo))*100


    const bar = (complete) => <div className='h-[16px] rounded-full  w-full bg-black/50 min-w-[50px]' >
        {complete ? <>
          <div className='bg-amber-400 h-full border-[1.5px] border-black/70  rounded-full transition-all ' style={{width: `100%`}}></div>
          <div className='h-[4px] -mt-[14px] mx-2 rounded transition-all bg-white/60' style={{width: `calc(100% - 12px)`}}></div>
            <div className='h-[5px] mt-[3px] mx-1 rounded transition-all bg-amber-900/20' style={{width: `calc(100% - 14px)`}}></div>
        
        </> : <div></div>}
      </div>
   

   

    

    if (toDoCards && toDoCards[0]) {return (
        <div id="backdrop" className='no-last-time flex flex-col items-between justify-between' style={{ position: "fixed", top: 0, right: 0, bottom: 0, left: 0, backgroundColor: "rgba(0, 50, 0, 0.5)", zIndex: 500}}>
          {/* <div className='flex gap-2'>
            <div>ct.lessonOfTheDay: {ct.lessonOfTheDay}</div>
            <div onClick={() => console.log('props.deckData', props.deckData)}>deck data</div>
            <div onClick={() => ct.setShowCards(props.deckData.nextCards)}>Relancer</div>
            <div onClick={() => {
              ct.setLessonOfTheDay(2)
              ct.setShowCards(props.deckData.nextCards)
              const today = new Date();
              const dateOnly = new Date(today.getFullYear(), today.getMonth(), today.getDate());

              ct.setUserWorkspace(u => ({...u, last_dm: dateOnly }))

              }}>Terminer</div>
          </div> */}
        <div className="header-modal mt-2 flex items-center p-2 w-full h-[30px] text-white gap-2 no-last-time " > 
            <svg onClick={() => {ct.setShowCards(); ct.setLessonOfTheDay(); done > 0 && ct.setShowPop('dailyMission') }}  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="no-last-time w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75" />
          </svg>

          {/* <div id="snake" className='flex fixed gap-2 grow justify-center w-full mx-auto'>
            {showCards &&  showCards.length > 1 && showCards.map(c => <div className={`${toDoCards[0].original_card.id == c.id ? "bg-yellow-300 scale-150" : masteredCards.find(mc => mc.original_card.id == c.id) ? "bg-green-200" : reviewCards.find(mc => mc.original_card.id == c.id) ? "bg-purple-400 mt-2" : "bg-white" } transition  h-1 grow rounded `} style={{maxWidth: 30, minWidth: 2}}></div>)}
          </div> */}
           {/* <div id="snake" className='flex fixed flex-wrap gap-2 grow top-4 bg-blue-500 justify-center mx-auto'>
            {toDoCards.map(c => <div className={`${toDoCards[0].original_card.id == c.id ? "bg-yellow-300 scale-150" : masteredCards.find(mc => mc.original_card.id == c.id) ? "bg-green-200" : reviewCards.find(mc => mc.original_card.id == c.id) ? "bg-purple-400 mt-2" : "bg-white" } transition  h-1 grow rounded `} style={{maxWidth: 30, minWidth: 2}}>
            <div className={`${ !c.user_card?.next_date ? "text-yellow-500" : c.user_card?.next_trigger == 1 ? "text-purple-500" : "text-green-500"} text-xs text-white`}>{c.original_card.term} {c.user_card?.next_trigger}</div>
            </div>)}
          </div> */}

          {ct.lessonOfTheDay == 2 &&  <div>{bar(true)}</div>}
          {showCards.length > 1 && 
          <div className='h-[16px] rounded-full overflow-hidden  w-full bg-black/50 grow' >
            <div className='bg-amber-400 h-full border-[1.5px] border-black/70  rounded-full transition-all ' style={{width: `${(progressCent)}%`}}></div>
            <div className='h-[4px] -mt-[14px] mx-2 rounded transition-all bg-white/60' style={{width: `calc(${(progressCent)}% - 12px)`}}></div>
            <div className='h-[5px] mt-[3px] mx-1 rounded transition-all bg-amber-900/20' style={{width: `calc(${progressCent}% - 14px)`}}></div>
            <div className='text-center text-shadow fredoka font-bold -mb-4 mt-[-20px]'><span className='text-amber-200 mr-[1px]'>{done}</span>/{toDo}</div>
          </div> 
          }
             {ct.lessonOfTheDay == 1 &&  <div>{bar(false)}</div>}
          <svg onClick={() => setShowParam(true)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.325.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 0 1 1.37.49l1.296 2.247a1.125 1.125 0 0 1-.26 1.431l-1.003.827c-.293.241-.438.613-.43.992a7.723 7.723 0 0 1 0 .255c-.008.378.137.75.43.991l1.004.827c.424.35.534.955.26 1.43l-1.298 2.247a1.125 1.125 0 0 1-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.47 6.47 0 0 1-.22.128c-.331.183-.581.495-.644.869l-.213 1.281c-.09.543-.56.94-1.11.94h-2.594c-.55 0-1.019-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 0 1-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 0 1-1.369-.49l-1.297-2.247a1.125 1.125 0 0 1 .26-1.431l1.004-.827c.292-.24.437-.613.43-.991a6.932 6.932 0 0 1 0-.255c.007-.38-.138-.751-.43-.992l-1.004-.827a1.125 1.125 0 0 1-.26-1.43l1.297-2.247a1.125 1.125 0 0 1 1.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.086.22-.128.332-.183.582-.495.644-.869l.214-1.28Z" />
            <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
          </svg>

           {/* {masteredCards.length} \  {showCards.length} - r: {reviewCards.length} */}
      
        </div> 
        {showDeck && <div className='fixed fredoka z-10 top-0 h-[100vh] overflow-scroll pb-12 p-4 bottom-0 left-0 right-0 bg-amber-50'>
          <div onClick={(e) => {setShowDeck(false); e.stopPropagation()}}><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
          </div>
          <div className='mx-auto max-w-[700px]' onClick={(e) => { e.stopPropagation()}}>
          <img src="/images/UX/professor.svg" className='h-[108px] mx-auto' />
          <div className='text-2xl text-amber-600 my-4 text-center '>{deck?.name}</div>
          <SuperEditor content={deck?.content} className={"mb-8"} />
          {deck?.childs?.map(d => <div onClick={() => setSelectedDeck(d)} className={` ${selectedDeck == d ? "bg-yellow-100/30 -mx-4 mb-14 " : "bg-white rounded-xl"} p-4 mb-2  transition border-2 border-b-4 border-amber-500/50`}>
            <div className='text-xl text-amber-500 my-3  '>{d?.name}</div>
            {selectedDeck == d && <SuperEditor content={d?.content} />}
          </div>)}
          <div className='flex justify-center my-4'>
          <div className='text-center mt-4 border-2 border-b-4 bg-amber-500 border-amber-700 text-white text-amber-500 fredoka px-4 py-1 rounded-xl ' onClick={()=> {
            const newUserDeck = {...ct.userDecks?.find(ud => ud.deck_id == deck.id), read: true}
            ct.fire.updateUserDeck(newUserDeck)
            ct.setUserDecks(ct.userDecks.map(ud => ud.id == newUserDeck.id ? newUserDeck : ud))
            setShowDeck(false)
          }}>
            Apprendre avec des exercices
          </div>
          </div>
          {/* <div className='flex gap-4 rounded-xl items-center bg-white mt-8 p-4 border-2 border-b-4'>
            
            <img src="/images/UX/professor_round.png" className='h-[80px] mx-auto' />
            <div className='grow'>
              <div>Tu as des questions sur cette leçon ?</div>
              <div className='text-sm italic text-slate-400'>Pose les moi directement ici</div>
            </div>
          </div> */}
          </div>
          </div>}
     
   
        <div id="flashcard" ref={flashcardRef} className={`${isFliped ? "hover" : ""} flip flashcard  `}>
          <div className="front recto " style={{backgroundColor: toDoCards[0]?.user_card?.next_date ? trigger?.bgColor : " "}} onClick={(e) => e.stopPropagation()}>
          {toDoCards[0]?.original_card?.model == "grammar" && 
          <>
            <div onClick={() => setShowDeck(true)} className='text-amber-500 absolute top-4 left-0 right-0 w-full text-center '>{deck?.name} </div> 
                  <div onClick={() => setShowDeck(true)} className='text-amber-500 absolute bottom-4 text-lg left-0 right-0 w-full text-center flex items-center justify-center '>
   </div> 
                </>}
          {toDoCards[0]?.user_card?.next_date ? toDoCards[0]?.original_card?.model == "lang-en" && 
               <div className={`w-full rounded-t-3xl p-4 `} style={{backgroundColor: trigger?.bgColor, color: trigger?.color}}>
                  {/* <div onClick={async()=> {   
                    const updatedCard = await validDB(true); 
                    validFlashCard(true, updatedCard)
                    }} className={`inline-block rounded-full text-white px-4 mb-1`} style={{backgroundColor: trigger?.color}}>{trigger?.name}</div> */}
                  <div onClick={async() => { const updatedCard = await validDB(true);  validFlashCard(true, updatedCard) }}  className={`rounded-full px-4`}>{trigger?.consign[0]?.replace('@lang', ct.workspace.name)}</div>
                </div> :  toDoCards[0]?.original_card?.model == "grammar" ? <div></div>: 
                <div>
                  {/* <div onClick={async() => { 
                    const updatedCard = await validDB(true); 
                    validFlashCard(true, updatedCard); 
                    }} className='px-2 bg-amber-400 inline-block mx-auto rounded-full text-white mt-2'>Nouveau
                  </div> */}
 
                </div>
                }
            <div className="bg-blue-500">
             <div className={"tips "}> 
              </div>
            </div>
            <div className=" text-center  flex flex-col max-h-[550px] grow block  items-center p-[16px] pt-0 overflow-y-auto" style={{justifyContent: "safe center"}}>
              <div className='max-w-[100%]  flex flex-col  max-h-[500px] text-center'>
             
               
                  {toDoCards[0]?.original_card && showTrigger(toDoCards[0]?.original_card, toDoCards[0]?.user_card, trigger)}
                  {hintMode && _hint}

                 
              </div>
            </div>
           
           {showInput(trigger)}
           
          </div>
          <div className="back ">
             <div className="top"></div>
             {_back}
            <div className="p-4 no-flip ">
            {card.model != "classic" && 
            <div className=''>
             
              
              {!hintMode && <div className='flex wrap flex-wrap items-center justify-end w-full justify-between gap-2 px-3 left-0 absolute bottom-[12px]'>
                {/* <div className='w-full opacity-[0.7] text-sm'>{trigger?.name}</div> */}
    
                {haveChoiceTooHard ? <div onClick={(e) => {
                  e.stopPropagation(); 
                
                  const _updatedCard = {...updatedCard, user_card: {...updatedCard.user_card, hard_exp: true}}
                  console.log('updatedCard', _updatedCard)
                  const UcardRef = doc(db, 'user_cards', updatedCard?.user_card?.id);
                  updateDoc(UcardRef, {hard_exp: true})
                  

                  validFlashCard(error == 0, _updatedCard)
                  
                  playNextSound()
                    
                  }} className={`-bg-[#d15193--] grow bg-[#bf44bf] px-4 rounded-2xl  border-b-4 border-2 border-black/50 text-white  font-sm   py-1 fredoka font-bold b `}>
                  {/* (nextDurationText == "maintenant" || nextDurationText == "0 s") ? "Continuer" : "Revoir dans " + nextDurationText */}
                  
                  <div className=''>Difficile</div>
                  <div className='text-xs font-medium opacity-[0.8]'>à revoir rapidement</div>
                  
                  

                  </div> : <div className=''></div>}
             
                {!onlyHard && !haveChoiceTooHard && <div onClick={(e) => {
                  e.stopPropagation(); 
                  validFlashCard(error == 0, updatedCard)
                  playNextSound()
                    if (error == 0) {
                      // setShowXP(true);
                      // setTimeout(() => setShowXP(false), 1000);
                    }
                  }} className={`   px-2 rounded-2xl ${haveChoiceTooHard ? "   bg-[#7351D1]" : "grow bg-[#7351D1]"}    border-b-4 border-2 border-black/50 text-white  font-sm   py-1 fredoka font-bold b `}>
                  {/* (nextDurationText == "maintenant" || nextDurationText == "0 s") ? "Continuer" : "Revoir dans " + nextDurationText */}
                  
                  <div className=''>{haveChoiceTooHard ? "Presque" : "Valider"}</div>
                  <div className='text-xs font-medium opacity-[0.8]'>revoir dans {nextDurationTextTrigger == "maintenant" || nextDurationTextTrigger == "0 s" ? "0 s" : nextDurationTextTrigger}</div>
                  
                  

                  </div>}
                  {(haveChoiceTooEasy || haveChoiceTooHard) && !onlyHard ? <div onClick={async() => {
                    const updatedCard = await validDB(2);  
                    validFlashCard(true, updatedCard)
                    playNextSound()
                  }} className={` ${haveChoiceTooHard ? "  border-black/50 bg-[#7351D1]" : " border-cyan-700 bg-cyan-500"}   grow border-2 border-b-4 text-white  font-sm rounded-2xl px-3 py-1 fredoka font-bold b `}>
                    {haveChoiceTooHard ? "Valider" : "Trop facile"}
                    <div className='text-xs font-medium opacity-[0.8]'>revoir dans {nextDurationTextTriggerEasy == "maintenant" || nextDurationTextTriggerEasy == "0 s" ? "0 s" : nextDurationTextTriggerEasy }</div>

                    </div> : <div className=''></div>}
                 

              </div>}
              </div>}
            </div>
          
          </div>
         
        </div>
        <div></div>
        {showParams}
        {showXP && <div className="xp-animation !top-[32px]">+1xp</div>}
        <div ref={xpRef} className='text-xl text-right fredoka font-semibold w-[70px] text-shadow-orange text-yellow-200 rounded-xl z-[999] left-20 px-1 opacity-0 ' style={{ position: 'absolute'}}>
        +1 xp
      </div>
      </div>
    
    ) } else {return <div> </div>}
}


export {LearnContainer, findHint, validSound, badSound}  


